import { LoadingOutlined } from '@ant-design/icons'
import { Table as AntdTable } from 'antd'
import { throttle } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import styles from './table.module.scss'

const sortOrders = {
    ascend: 'ASC',
    descend: 'DESC',
}

const Table = ({ columns, data, hasMore, loadMore, isLoading, onChange, onRowClick, ...otherProps }) => {
    const tableRef = useRef()
    const endElementRef = useRef(null)
    const observer = useRef(null)
    const scrollListener = useRef(loadMore)

    // Throttle the loadMore function
    const debouncedLoadMore = useRef(
        throttle(() => {
            scrollListener.current?.()
        }, 200)
    ).current

    useEffect(() => {
        scrollListener.current = loadMore

        return () => {
            debouncedLoadMore.cancel()
        }
    }, [loadMore])

    useEffect(() => {
        if (!endElementRef.current || observer.current) return

        observer.current = new IntersectionObserver((entries) => {
            const anyIntersecting = entries.some((entry) => entry.isIntersecting)

            if (anyIntersecting) {
                debouncedLoadMore()
                if (!hasMore) {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) observer.current?.unobserve(entry.target)
                    })
                }
            }
        })

        observer.current.observe(endElementRef.current)

        return () => {
            observer.current?.disconnect()
            observer.current = null
        }
    }, [hasMore, debouncedLoadMore])

    return (
        <div className={styles.wrapper}>
            <AntdTable
                columns={columns}
                dataSource={data}
                ref={tableRef}
                rowKey="id"
                pagination={false}
                onChange={(...[, , { field, order }]) => {
                    onChange({
                        sortBy: Array.isArray(field) ? field.join('.') : field,
                        sortType: sortOrders[order] ?? undefined,
                    })
                }}
                // loading={isLoading}
                onRow={(record, rowIndex) => ({
                    onClick: (event) => {
                        if (event.target.querySelector('input[type="checkbox"]')) {
                            return
                        }
                        event.preventDefault()
                        onRowClick?.(rowIndex, record)
                    },
                })}
                scroll={{ x: 'max-content'/* , y: 'max-content' */ }}
                className="table--fullwidth"
                // sticky={{
                //     offsetHeader: -2,  // Adjusts the offset from the top (e.g., if a fixed navbar is present)
                // }}
                // sticky
                {...otherProps}
            />
            <div ref={endElementRef} className={styles.scrollToEnd}>
                {(hasMore && isLoading) ? <LoadingOutlined /> : 'No more items'}
            </div>
        </div>
    )
}

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onRowClick: PropTypes.func,
}

export default Table