import { Collapse, message } from 'antd';
import React from 'react';
import { redirect, useFetcher, useRouteLoaderData } from 'react-router-dom';
import { sendProofCreateRequesttoStudio, sendProoftoClient } from '../../api/order/order-manager.js';
import FileTransfer from '../../components/inputs/FileTransfer.jsx';
import { generateRouteUrl } from '../../library/constants/routes.js';
const { Panel } = Collapse

const MakeCustomerArtwork = () => {
    const { orderDetails, orderFiles } = useRouteLoaderData('OrderManagerFileTransfer');
    const fetcher = useFetcher()

    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header='Digital Proof' key="1">
                <FileTransfer
                    existingFiles={orderFiles?.client_artwork ?? []}
                    orderDetails={orderDetails}
                    orderManagerStudioActionText="Sent to Customer"
                    loading={fetcher.state !== 'idle'}
                    tabDescriptions={{
                        sendToStudio: (<strong>Request Studio to create digital proof</strong>),
                        orderManager: (<strong>Upload and send digital proof to customer</strong>),
                    }}
                    onSendToStudio={(data) => fetcher.submit({
                        ...data,
                        action: 'sendToStudio'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })}
                    onOrderManagerStudio={(data) => fetcher.submit({
                        ...data,
                        action: 'sendToCustomer'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })}
                />
            </Panel>
        </Collapse>
    )
}
MakeCustomerArtwork.Actions = {
    sendToStudio: async ({ params, data }) => {
        try {
            const { comment } = data
            const msg = await sendProofCreateRequesttoStudio(params.itemId, {
                images: [],
                image_ids: [],
                comment
            })
            message.success(msg)
            return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    },
    sendToCustomer: async ({ params, data }) => {
        try {
            const { newFiles = [], comment } = data
            const msg = await sendProoftoClient(params.itemId, {
                images: [],
                image_ids: newFiles,
                comment
            })
            message.success(msg)
            return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    },
}
export default MakeCustomerArtwork