export const StudioKanabanColumn =
    [
        {
            key: 1,
            title: 'Template Request',
        },
        {
            key: 2,
            title: 'Sent Template',
        },
        {
            key: 3,
            title: 'Create Digital Proof Request',
        },
        {
            key: 4,
            title: 'Digital Proof Sent',
        },
        {
            key: 5,
            title: 'Update Vendor Proof Request',
        },
        {
            key: 6,
            title: 'Vendor Proof Sent',
        },
    ]