import { Button, Collapse, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { generatePath, redirect, useFetcher } from 'react-router-dom';
import { makeContact } from '../../api/contact/contact';
import { Contact } from '../../components/form';
import { Page } from '../../components/page';
import { ValidationError } from '../../library/classes/exceptions';
const { Panel } = Collapse

//import Customer from 'components/form/customer/customer.jsx'

const ContactCreate = ({ title }) => {
	const fetcher = useFetcher()
	// const [isLoading, setIsLoading] = useState(true)
	// const navigate = useNavigate()
	// useEffect(() => {
	// 	setIsLoading(true)
	// 	getContact(contactId)
	// 		.then(({ data }) => {
	// 			setCustomerContactDetails(data)
	// 			setIsLoading(false)
	// 		})
	// 		.finally(() => {
	// 			setIsLoading(false)
	// 		})
	// }, [])


	const handleCustomerDetailContactsSubmit = (values) => {
		fetcher.submit({
			...values,
			action: 'contactCreate'
		}, {
			method: 'post',
			encType: 'application/json'
		})
	}

	return (
		<Page className='customers-detail' title={title}>
			{
				<Collapse defaultActiveKey={['1']}>
				<Panel header={<><img src="/icons/headericons/contacts.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Contact Details</>} key="1" >
						<Contact errors={fetcher.data?.errors} onFinish={handleCustomerDetailContactsSubmit}>
							<Button type="primary" htmlType="submit">Save</Button>
						</Contact>
					</Panel>
				</Collapse>
			}
		</Page>
	)
}

ContactCreate.propTypes = {
	title: PropTypes.string
}

ContactCreate.Actions = {
	contactCreate: async ({ data }) => {
		try {
			const { message: msg, data: d } = await makeContact(data)
			message.success(msg)
			return redirect(generatePath('/customers/contacts/:id', {
				id: d.id //maybe to some other route
			}))
			//	return trueF
		}
		catch (error) {
			message.error(error.message)

			if (error instanceof ValidationError) {
				const formattedErrors = {
				}
				Object.entries(error.errors).forEach(([k, v]) => {
					formattedErrors[k] = v
				})

				return {
					errors: formattedErrors
				}

			}



		}
		return false
	},
}

export default ContactCreate