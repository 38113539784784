import { Collapse, message } from 'antd';
import React from 'react';
import { redirect, useFetcher, useRouteLoaderData } from 'react-router-dom';
import { proofRequested, rejectArtwork } from '../../api/order/order-manager.js';
import FileTransfer from '../../components/inputs/FileTransfer.jsx';
import { generateRouteUrl } from '../../library/constants/routes.js';
const { Panel } = Collapse

const ProofReRequestVedor = () => {
    const { orderDetails, orderFiles } = useRouteLoaderData('OrderManagerFileTransfer');
    const fetcher = useFetcher()
    const existingFiles = (orderFiles?.make_artwork_send_to_client?.length > 0 ? orderFiles.make_artwork_send_to_client : orderFiles?.client_artwork) ?? []

    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header='Customer Artwork' key="1">
                <FileTransfer
                    existingFiles={existingFiles}
                    orderDetails={orderDetails}
                    orderManagerStudioActionText="Send Proof to Customer"
                    loading={fetcher.state !== 'idle'}
                    tabDescriptions={{
                        // sendToStudio: (<strong>Request Studio to create digital proof.</strong>),
                        // orderManager: (<strong>Upload and send digital proof to customer</strong>),
                        sendToVendor: (<strong>Re-request vendor to create digital proof again.</strong>),
                        reject: (<strong>Reject customer artwork so that they can upload it again.</strong>),
                    }}
                    onSendToStudio={(data) => fetcher.submit({
                        ...data,
                        action: 'sendToStudio'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })}
                    onOrderManagerStudio={(data) => fetcher.submit({
                        ...data,
                        action: 'sendProofToCustomer'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })}
                    onSendToVendor={(data) => fetcher.submit({
                        ...data,
                        action: 'sendToVendor'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })}
                    onReject={(data) => fetcher.submit({
                        ...data,
                        action: 'rejectArtwork'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })}
                />
            </Panel>
        </Collapse>
    )
}
ProofReRequestVedor.Actions = {
    // sendToStudio: async ({ params, data }) => {
    //     try {
    //         const { existingFiles = [], comment } = data
    //         const payload = {
    //             images: existingFiles,
    //             image_ids: [],
    //             comment
    //         }
    //         const { message: msg } = await sendProofCreateRequesttoStudio(params.itemId, payload)
    //         message.success(msg)
    //         return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
    //     }
    //     catch (error) {
    //         message.error(error.message)
    //         return false
    //     }
    // },
    // sendProofToCustomer: async ({ params, data }) => {
    //     try {
    //         const { existingFiles = [], newFiles = [], comment } = data
    //         const payload = {
    //             images: existingFiles,
    //             image_ids: newFiles,
    //             comment
    //         }
    //         const { message: msg } = await sendProoftoClient(params.itemId, payload)
    //         message.success(msg)
    //         return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
    //     }
    //     catch (error) {
    //         message.error(error.message)
    //         return false
    //     }
    // },
    sendToVendor: async ({ params, data }) => {
        try {
            const { existingFiles = [], newFiles = [], comment } = data
            const payload = {
                images: existingFiles,
                image_ids: newFiles,
                comment
            }
            const { message: msg } = await proofRequested(params.itemId, payload)
            message.success(msg)
            return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    },
    rejectArtwork: async ({ params, data }) => {
        try {
            const { comment } = data
            const { message: msg } = await rejectArtwork(params.itemId, comment)
            message.success(msg)
            return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    },
}
export default ProofReRequestVedor;