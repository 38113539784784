import { message } from 'antd';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  emailLogin, /* , fetchAccessToken */
  getNotifications
} from '../../api/auth';
import useLocalStorage from '../../hooks/use-local-storage.js';
import { LoginProvider, setUserSessionData } from '../../library/constants';
import { AuthContext } from './auth-context';

function AuthProvider(props) {
  const { children } = props;

  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const { pageTitle, setPageTitle } = useState();
  const [roles, setRoles] = useLocalStorage('UAR', []);
  const [user, setUser] = useState({});

  useEffect(() => {
    console.log({ roles })
  }, [roles])

  // const getProfile = async () => {
  //   try {
  //     const { data } = await fetchProfile();
  //     const userData = data?.[0];
  //     setUser(userData);
  //   } catch (e) {
  //     console.log('errored out', e)
  //   } finally {
  //     setAuthLoading(false);
  //     setPageLoading(false);
  //   }
  // }

  const handleAuth = (tokenDetails) => {
    localStorage.setItem('USER', JSON.stringify(tokenDetails));
    localStorage.setItem('ACCESS_TOKEN', tokenDetails?.a_tkn?.tkn);
    localStorage.setItem('REFRESH_TOKEN', tokenDetails?.r_tkn?.tkn);
    setRoles(tokenDetails?.roles);
    setUser(tokenDetails)
    setAuthLoading(false)
    setPageLoading(false)
    // window.location.reload()
    //getProfile();
  };

  const setToken = async (type, payload) => {
    try {
      //fetchAccessToken
      // const { token } = await fetchAccessToken({
      //   os: 'web',
      //   tenant: 'ADMIN',
      //   device_id: localStorage.getItem('DEVICE_ID'),
      //   country: '1'
      // });

      // const headers = {
      //   Authorization: `Bearer ${token}`,
      // }
      const { data } = await emailLogin({}, payload);
      //Get notifications
      console.log('fetching login notifications....')
      const { notifications, unreadCount } = await getNotifications()
      setUserSessionData('NOTIFICATIONS', JSON.stringify({ notifications, unreadCount }))
      handleAuth(data);
    } catch (e) {
      console.log('LOGIN_ERROR: ', e);
      message.error(e.message)
    }
  }

  const handleLogin = (type, payload) => {
    setAuthLoading(true);
    if (type === LoginProvider.EMAIL && !isEmpty(payload)) {
      setToken(LoginProvider.EMAIL, payload);
    } else {
      console.error('invalid login provider type');
    }
  }

  const handleLogout = () => {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    localStorage.removeItem('USER');
    localStorage.removeItem('NOTIFICATION');
    setUser({});
  };

  const handlePageState = () => {
    // do something before setting state
    setPageLoading((prev) => !prev);
  }

  useEffect(() => {
    // if (localStorage.getItem('ACCESS_TOKEN')) {
    //   getProfile();
    // } else {
    //   setPageLoading(false);
    // }
    if (localStorage.getItem('USER')) {
      setUser(JSON.parse(localStorage.getItem('USER')))
    }
    setPageLoading(false);
  }, []);


  const state = useMemo(
    () => ({
      user,
      roles: roles ?? [],
      isAuthLoading,
      isPageLoading,
      pageTitle,
      actions: {
        onLogin: handleLogin,
        onLogout: handleLogout,
        updatePageLoading: handlePageState,
        setPageTitle: setPageTitle,
      }
    }),
    [handleAuth, handleLogin, handleLogout, isPageLoading, roles, isAuthLoading, user]
  );

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}

export default AuthProvider
