// import { UserOutlined } from '@ant-design/icons';
import { Collapse, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, redirect, useRouteLoaderData, useSearchParams, useSubmit } from 'react-router-dom';
import { getCustomerDetails } from '../../api/customer/customer-details.js';
import { createProject } from '../../api/project';
import { ContactDescription } from '../../components/data';
import { SmallSection } from '../../components/design';
import { ProjectDetails } from '../../components/form';
import { Page } from '../../components/page';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { defaultPaymentConditionId } from '../../library/constants/defaults.js';
const { Panel } = Collapse

const ProjectCreate = ({ title }) => {
	const { user } = useRouteLoaderData('Root')
	const [searchParams,] = useSearchParams()
	const customer_id = useMemo(() => {
		return searchParams.has('customer_id') && !isNaN(Number(searchParams.get('customer_id')))
			? Number(searchParams.get('customer_id'))
			: null
	}, [searchParams])
	const [initialValues, setInitialValues] = useState({
		account_manager_id: user.id,
		payment_condition_id: defaultPaymentConditionId,
		customer_id
	})

	const [customerContact, setCustomerContact] = useState(null)
	const submit = useSubmit()

	useEffect(() => {
		if (!customer_id) {
			return
		}
		getCustomerDetails(customer_id).then(customer => {
			setInitialValues(_prev => ({
				..._prev,
				customer_id,
				customer: {
					id: customer.id,
					company: customer.company,
				},
				entity_id: customer.entity_id,
				payment_term_id: customer.payment_term_id,
			}))
			handleCustomerChange(customer)
		})
	}, [customer_id])

	const projectDetailsForm = useRef({
		isValid: false,
		data: {}
	})

	const handleCustomerChange = async (customer) => {
		if (!customer?.primary_contact_id || !customer?.contacts) {
			setCustomerContact(null)
			return
		}

		const primaryContact = customer?.contacts.find(({ id }) => id === customer.primary_contact_id)
		if (!primaryContact) {
			setCustomerContact(null)
			return
		}

		setCustomerContact(primaryContact)
		projectDetailsForm.current.data.primary_contact_id = primaryContact.id
	}

	const handleProjectDetailsChange = async (_, values) => {
		projectDetailsForm.current.data = { ...values, ['primary_contact_id']: projectDetailsForm.current.data.primary_contact_id }

		if (!projectDetailsForm.current.isValid) {
			return
		}

		submit(projectDetailsForm.current.data, {
			method: 'post',
			encType: 'application/json'
		})
	}

	return (
		<Page className='projects-create' title={title} >
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				alignTtems: 'stretch',
			}} >
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><img src="/icons/headericons/projectinformation.svg" style={{ width: '1rem', verticalAlign: 'text-top', marginRight: '8px' }} />Project Information</>} key="1">
						<ProjectDetails
							key={JSON.stringify(initialValues)}
							initialValues={initialValues}
							getValidationStatus={(isValid) => { projectDetailsForm.current.isValid = isValid }}
							onCustomerChange={handleCustomerChange}
							onValuesChange={handleProjectDetailsChange}
						/>
					</Panel>
				</Collapse>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><img src="/icons/headericons/contacts.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }}/>Contact Information</>} key="1">
						{customerContact &&
							<SmallSection>
								<ContactDescription contact={customerContact} />
							</SmallSection>
						}
					</Panel>
				</Collapse>
			</div>
		</Page>
	)
}

ProjectCreate.propTypes = {
	title: PropTypes.string
}

ProjectCreate.Action = async ({ request }) => {
	try {
		const payload = await request.json()
		const { message: msg, data } = await createProject(payload)
		message.success(msg)
		return redirect(generatePath('/projects/:id', {
			id: data.id
		}))
	}
	catch (error) {
		message.error(error.message)
		if (error instanceof ValidationError) {
			return {
				errors: error.errors
			}
		}
	}
	return false
}

export default ProjectCreate