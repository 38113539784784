// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/item/:itemId/delivery-address';
const ENDPOINT_SINGLE = '/item/:itemId/delivery-address/:deliverId';
const ENDPONT_BULK_UPLOAD = '/item/:itemId/bulk-upload-address';

export const updateItemDeliveryAddresses = (itemId, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT, {
        itemId,
    }), payload).then(({ data }) => data)
}

export const addItemDeliveryAddress = (itemId, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT, {
        itemId,
    }), payload).then(({ data }) => data)
}

export const updateItemDeliveryAddress = (itemId, deliverId, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        itemId,
        deliverId
    }), payload).then(({ data }) => data)
}

export const deleteItemDeliveryAddress = (itemId, deliverId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE, {
        itemId,
        deliverId
    })).then(({ message }) => message)
}
export const bulkItemDeliveryAddress = (itemId, additional_cost, notes, margin, file_id) => {
    return axiosInstance.post(generatePath(ENDPONT_BULK_UPLOAD, {
        itemId
    }), {
        ...(file_id && { file_id }),
        additional_cost,
        notes,
        margin,
    }).then(({ message }) => message)
}
export const deleteBulkDeliveryFile = (itemId,fileId) => {
    return axiosInstance
        .delete(generatePath(ENDPONT_BULK_UPLOAD, { itemId,fileId }))
        .then(({ message}) => message)
};