import {
	Input,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidationForm as Form } from '../';
import { CountrySelect, GeneralFieldWrapper } from '../../inputs';
import styles from './customer-address.module.scss';

const CustomerAddressDisabled = ({ data = {} }) => (
	<div className="form-fields-wrapper form-fields-wrapper" style={{ alignItems: 'start' }}>
		<GeneralFieldWrapper label="Street">{data.street}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Street Number">{data.street_number}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Additional Field">{data.additional_field_1}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Additional Field 2">{data.additional_field_2}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Zipcode">{data.zipcode}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="City">{data.city}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="County">{data.county}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Country">{data.country?.name}</GeneralFieldWrapper>
	</div>
)

CustomerAddressDisabled.propTypes = {
	data: PropTypes.object.isRequired
}

const CustomerAddress = ({ children, index, getValidationStatus, ...otherProps }) => {
	const [form] = Form.useForm()
	const validationRules = {
		street: [
			{
				required: true,
				message: 'Street is required'
			},
			{
				max: 100,
				message: 'Street is too long'
			}
		],
		street_number: [
			{
				required: true,
				message: 'Street number is required',
			},
			{
				max: 100,
				message: 'Street number is too long'
			}
		],
		city: [
			{
				required: true,
				message: 'City is required'
			}
		],
		zipcode: [
			{
				required: true,
				message: 'Zipcode is required'
			}
		],
		country_id: [
			{
				required: true,
				message: 'Country is required'
			}
		],
	}

	if (otherProps.disabled) {
		return (
			<CustomerAddressDisabled data={otherProps?.initialValues} />
		)
	}

	return (
		<Form form={form} name={`address-${index}`} onValidationStatusChange={getValidationStatus} layout="vertical" className={styles.FormWrapper} validationRules={validationRules} {...otherProps}>
			<Form.Item name="street" label="Street">
				<Input />
			</Form.Item>
			<Form.Item name="street_number" label="Street Number">
				<Input />
			</Form.Item>
			<Form.Item name="additional_field_1" label="Additional Field">
				<Input placeholder='Building' />
			</Form.Item>
			<Form.Item name="additional_field_2" label="Additional Field 2">
				<Input placeholder='Floor' />
			</Form.Item>
			<Form.Item name="zipcode" label="Zipcode">
				<Input type="text" maxLength={15} />
			</Form.Item>
			<Form.Item name="city" label="City">
				<Input type="text" />
			</Form.Item>
			<Form.Item name='county' label="County">
				<Input type="text" />
			</Form.Item>
			<Form.Item name='country_id' label="Country">
				<CountrySelect />
			</Form.Item>
			{children}
		</Form>
	)
}

CustomerAddress.propTypes = {
	children: PropTypes.node,
	index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	getValidationStatus: PropTypes.func,
}

export default CustomerAddress