import {
	Input,
	Select
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ValidationForm as Form } from '../';
import Genders from '../../../library/dynamics/genders';
import { normalizeEmail } from '../../../library/helpers/normalize.js';
import { GeneralFieldWrapper, LanguageSelect, PhoneInput } from '../../inputs';
import PhoneDisplay from '../../inputs/PhoneDisplay.jsx';
import styles from './vendor-contact.module.scss';

const VendorContactDisabled = ({ data = {} }) => (
	<div className="form-fields-wrapper form-fields-wrapper" style={{ alignItems: 'start' }}>
		<GeneralFieldWrapper span={4} label="Role">{data.role?.name}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="First Name">{data.first_name}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Last Name">{data.last_name}</GeneralFieldWrapper>
		<div className=""></div>
		<GeneralFieldWrapper label="Email Address">{data.email}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Phone Number"><PhoneDisplay code={data.phone_country_code} number={data.phone_number} /></GeneralFieldWrapper>
		<GeneralFieldWrapper label="Mobile Number"><PhoneDisplay code={data.mobile_country_code} number={data.mobile_number} /></GeneralFieldWrapper>
		<GeneralFieldWrapper label="Gender">{data.gender?.name}</GeneralFieldWrapper>
		<GeneralFieldWrapper label="Language">{data.language?.name}</GeneralFieldWrapper>
	</div>
)

VendorContactDisabled.propTypes = {
	data: PropTypes.object.isRequired,
}

const VendorContact = ({ children, index, allowedLanguages, getValidationStatus, ...otherProps }) => {
	const [form] = Form.useForm()
	const validationRules = {
		first_name: [
			{
				required: true,
				message: 'First Name is required'
			}
		],
		last_name: [
			{
				required: true,
				message: 'Last Name is required'
			}
		],
		phone_number: [
			{
				required: true,
				message: 'Phone Number is required'
			}
		],
		email: [
			{
				required: true,
				message: 'Email is required'
			},
			{
				type: 'email',
				message: 'Email is not valid'
			},
		],
		gender_id: [
			{
				required: true,
				message: 'Gender is required'
			}
		],
		company_role: [
			{
				required: true,
				message: 'Role is required'
			}
		],
		language_id: [
			{
				required: true,
				message: 'Language is required'
			}
		],
	}

	useEffect(() => {
		if (allowedLanguages?.length === 1) {
			form.setFieldValue('language_id', allowedLanguages[0]);
		}
	}, [allowedLanguages]);

	if (otherProps.disabled) {
		return (
			<VendorContactDisabled data={otherProps?.initialValues} />
		)
	}

	return (
		<Form form={form} name={`contact-${index}`} layout="vertical" className={styles.FormWrapper} onValidationStatusChange={getValidationStatus} validationRules={validationRules}  {...otherProps}>
			<Form.Item name="first_name" label="First Name" >
				<Input placeholder="First Name" onKeyPress={(event) => {
					if (/[0-9]/.test(event.key)) {
						event.preventDefault();
					}
				}} maxLength={60}/>
			</Form.Item>
			<Form.Item name="last_name" label="Last Name" >
				<Input placeholder="Last Name" onKeyPress={(event) => {
					if (/[0-9]/.test(event.key)) {
						event.preventDefault();
					}
				}} maxLength={60}/>
			</Form.Item>
			<br />
			<Form.Item name="email" label="Email Address" normalize={normalizeEmail}>
				<Input type="email" placeholder="Email" />
			</Form.Item>
			<Form.Item label="Phone Number" required>
				<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} placeholder="Telephone No." label='Phone Number' />
			</Form.Item>
			<Form.Item label="Mobile Number">
				<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} placeholder="Mobile No." label='Mobile Number' />
			</Form.Item>
			<Form.Item name='gender_id' label="Gender" >
				<Select options={Genders} fieldNames={{ value: 'id', label: 'name' }} placeholder="Gender" />
			</Form.Item>
			<Form.Item name='language_id' label="Language" >
				<LanguageSelect options={allowedLanguages} />
			</Form.Item>
			{children}
		</Form>
	)
}
//suffixIcon={<Icon component={() => (<img src="/icons/dropdown.svg" />)} styles={{pointerEvents: 'none'}} />}
VendorContact.propTypes = {
	children: PropTypes.node,
	index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	getValidationStatus: PropTypes.func,
	allowedLanguages: PropTypes.array,
}

export default VendorContact