import React from 'react';
// import sentry only after react to prevent error
import * as Sentry from "@sentry/react";
import ReactDOM from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';

import './styles/index.less';

const getLoggedInUserDetails = () => {
  const user = JSON.parse(localStorage.getItem('USER'))
  if (!user)
    return null

  return {
    name: user.name,
    email: user.email
  }
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // integrations: [
    //   new Sentry.BrowserTracing({
    //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //     tracePropagationTargets: ["localhost:3016", "https://erpdemo.leoprinting.com"],
    //   }),
    //   // new Sentry.Feedback({
    //   //   // Additional SDK configuration goes in here, for example:
    //   //   colorScheme: "light",
    //   // }),
    //   new Sentry.Replay(),
    // ],
    tracesSampleRate: 0,
    beforeSend(event) {
      getLoggedInUserDetails()
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && event.event_id) {
        Sentry.showReportDialog({
          ...getLoggedInUserDetails(),
          eventId: event.event_id
        });
      }
      return event;
    },
    release: `version-${process.env.REACT_APP_CURRENT_BUILD}`,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Performance Monitoring
    // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  });
}

function removeLoading() {
  const loader = document.getElementById('pageloading');
  if (loader) {
    loader.style.display = 'none';
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'development') {
    root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    removeLoading()
  );
}
else {
  root.render(
    <App />,
    removeLoading()
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// //Register service worker
// if ('serviceWorker' in navigator) {
//   // Register a service worker hosted at the root of the
//   // site using the default scope.
//   navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then(
//     registration => {
//       console.log('Service worker registration succeeded:', registration);
//     },
//     /*catch*/ error => {
//       console.error(`Service worker registration failed: ${error}`);
//     }
//   );
// } else {
//   console.error('Service workers are not supported.');
// }