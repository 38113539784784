import { Collapse, message } from 'antd';
import React from 'react';
import { redirect, useFetcher, useRouteLoaderData } from 'react-router-dom';
import { sendTemplatetoCustomer, sendTemplatetoStudio } from '../../api/order';
import FileTransfer from '../../components/inputs/FileTransfer';
import { generateRouteUrl } from '../../library/constants';
const { Panel } = Collapse

const UploadSampleTemplate = () => {

    const { orderDetails, orderFiles } = useRouteLoaderData('OrderManagerFileTransfer');
    const fetcher = useFetcher()
    const existingFiles = orderFiles?.vendor_template ?? []

    return (
        (

            <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header={<><img src="/icons/headericons/sendtemplatetocustomer.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Send Template to Customer</>} key="1" >
                    <FileTransfer
                        existingFiles={existingFiles}
                        orderDetails={orderDetails}
                        orderManagerStudioActionText="Sent to Customer"
                        loading={fetcher.state !== 'idle'}
                        onSendToStudio={existingFiles.length ? (data) => fetcher.submit({
                            ...data,
                            action: 'sendToStudio'
                        }, {
                            method: 'post',
                            encType: 'application/json'
                        }) : undefined}
                        onOrderManagerStudio={(data) => fetcher.submit({
                            ...data,
                            action: 'sendToCustomer'
                        }, {
                            method: 'post',
                            encType: 'application/json'
                        })}
                        onSendToCustomer={existingFiles.length ? (data) => {
                            fetcher.submit({
                                ...data,
                                action: 'sendToCustomer'
                            }, {
                                method: 'post',
                                encType: 'application/json'
                            })
                        } : undefined}
                    />
                </Panel>
            </Collapse>

        )

    )
}
UploadSampleTemplate.Actions = {

    sendToStudio: async ({ params, data }) => {
        try {
            const { existingFiles = [], comment } = data
            const { message: msg } = await sendTemplatetoStudio(params.itemId, { images: existingFiles, comment, image_ids: [] })
            message.success(msg)
            return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    },
    sendToCustomer: async ({ params, data }) => {
        try {
            const { existingFiles = [], newFiles = [], comment } = data
            const payload = {
                images: existingFiles,
                image_ids: newFiles,
                comment
            }
            const { message: msg } = await sendTemplatetoCustomer(params.itemId, payload)
            message.success(msg)
            return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    },

}


export default UploadSampleTemplate