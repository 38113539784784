/* eslint-disable no-unused-vars */

import { CheckOutlined, CloseOutlined, DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Collapse, Popconfirm, message } from 'antd';
import { debounce } from 'lodash-es';
import { customAlphabet } from 'nanoid/non-secure';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { generatePath, redirect, useActionData, useFetcher, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import { createCustomer } from '../../api/customer/create-customer.js';
import { SmallSection } from '../../components/design';
import { Customer, CustomerAddress, CustomerContact, ValidationForm as Form } from '../../components/form';
import { AddressRoles, CustomerAddressRoleSelect } from '../../components/inputs/customer-address-roles-select.jsx';
import { ContactRoles, CustomerContactRoleSelect } from '../../components/inputs/customer-contact-roles-select.jsx';
import { Page } from '../../components/page';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { getCountryById } from '../../library/helpers/country.js';
import { getEntityById } from '../../library/helpers/entity.js';
import { getLanguageIdByCode } from '../../library/helpers/language.js';
import confirm from 'antd/lib/modal/confirm.js';
const { Panel } = Collapse
const nanoid = customAlphabet('1234567890', 4)
const numericNanoid = () => parseInt(nanoid())

const LOCAL_STORAGE_KEY = 'newCustomerDetails'
const DEBOUNCE_DURATION = 2000

const getSupportedLanguagesByCountryId = (countryId) => {
	if (!countryId) {
		return []
	}
	const country = getCountryById(countryId)
	const entity = getEntityById(country.default_entity_id)
	//Supported languages
	return entity?.default_language ?? [getLanguageIdByCode('en')] // English is default support language
}

const CustomerCreate = ({ title }) => {
	const { customerDetails, addresses, contacts, primaryAddressId, invoiceAddressId, deliveryAddressId, primaryContactId, invoiceContactId } = useLoaderData()
	const formData = useRef({ customerDetails, addresses: addresses.length ? addresses : [], contacts: contacts.length ? contacts : [], primaryAddressId, invoiceAddressId, deliveryAddressId, primaryContactId, invoiceContactId })

	const formValidationStatus = useRef({
		customerDetails: false,
		addresses: {},
		contacts: {},
	})

	const [form] = Form.useForm()

	const defaultCustomerDetails = { invoice_type_id: 'email', payment_term_id: 5 }
	const actionData = useActionData()
	const { errors = {} } = actionData || {}
	const { addressErrors = [], contactErrors = [], customerErrors = {} } = errors
	const submit = useSubmit()
	const [contactLanguageOptions, setContactLanguageOptions] = useState(getSupportedLanguagesByCountryId(customerDetails?.country_id))
	const fetcher = useFetcher()
	const navigate = useNavigate()
	const addressRef = useRef(null)
	const contactref = useRef(null)

	const submitLocally = () => {
		fetcher.submit({
			...formData.current,
			action: 'localUpdate'
		}, {
			method: 'post',
			encType: 'application/json'
		})
		// localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formData.current))
	}

	const submitLocallyDelayed = debounce(submitLocally, DEBOUNCE_DURATION)

	useEffect(() => {
		if (formData.current.addresses.length === 0) {
			handleAddNewAddress();
		}
		if (formData.current.contacts.length === 0) {
			handleAddNewContact();
		}
	}, []);

	const handleCustomerDetailsSubmit = async (changed, allValues) => {
		//Check if country is changed
		if ('country_id' in changed) {
			//Supported languages
			setContactLanguageOptions(getSupportedLanguagesByCountryId(changed.country_id))
		}
		if ('entity_id' in changed) {
			const entity = getEntityById(changed.entity_id)
			//Supported languages
			setContactLanguageOptions(entity?.default_language ?? [])
		}
		formData.current.customerDetails = { ...allValues, bad_payment_history: allValues.bad_payment_history === '1' ? 1 : 0 }
		// submitLocallyDelayed()
	}
	const handleCustomerAddressSubmit = async (id, address) => {
		formData.current.addresses = formData.current.addresses.toSpliced(addresses.findIndex(a => a.id === id), 1, address)
		submitLocallyDelayed()
	}

	const handleCustomerContactSubmit = async (id, contact) => {
		formData.current.contacts = formData.current.contacts.toSpliced(contacts.findIndex(a => a.id === id), 1, contact)
		submitLocallyDelayed()
	}

	const handleContactRoleChange = ({ contactId, type, action }) => {
		if (!Object.values(ContactRoles).includes(type)) {
			message.error('Invalid action');
			return;
		}
		if (!['add', 'remove'].includes(action)) {
			message.error('Invalid action');
			return;
		}
		if (type === ContactRoles.Authority) {
			const contact = formData.current.contacts.find(a => a.id === contactId);
			if (action === 'add') {
				contact.is_authority = true;
			} else if (action === 'remove') {
				contact.is_authority = false;
			}
			const contactIndex = formData.current.contacts.findIndex(a => a.id === contactId);
			formData.current.contacts = formData.current.contacts.toSpliced(contactIndex, 1, contact);
		} else {
			formData.current[`${type}ContactId`] = action === 'add' ? contactId : null;
		}

		submitLocally();
	}


	const handleAddressRoleChange = ({ addressId, type, action }) => {
		if (!Object.values(AddressRoles).includes(type)) {
			message.error('Invalid action')
			return
		}
		if (!['add', 'remove'].includes(action)) {
			message.error('Invalid action')
			return
		}

		formData.current[`${type}AddressId`] = action === 'add' ? addressId : null
		submitLocally()
	}

	const getAddressActions = (id) => {
		return ([
			<Popconfirm
				key="delete"
				title="Are you sure to delete this address?"
				onConfirm={() => {
					const index = formData.current.addresses.findIndex(a => a.id === id)
					formData.current.addresses = formData.current.addresses.toSpliced(index, 1)
					submitLocally()
				}}
			>
				<Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
			</Popconfirm>
		])
	}
	const getContactAction = (id) => {
		return ([
			<Popconfirm
				key="delete"
				title="Are you sure to delete this contact?"
				onConfirm={() => {
					const index = formData.current.contacts.findIndex(a => a.id === id)
					formData.current.contacts = formData.current.contacts.toSpliced(index, 1)
					submitLocally()
				}}
			>
				<Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
			</Popconfirm>
		])
	}

	const handleAddNewAddress = () => {
		formData.current.addresses.push({
			id: numericNanoid(),
			country_id: formData.current.customerDetails?.country_id
		})
		if (formData.current.addresses.length === 1) {
			handleAddressRoleChange({
				addressId: formData.current.addresses[0].id,
				type: 'primary',
				action: 'add'
			});
		}
		else {
			submitLocally()
		}
	}

	const handleAddNewContact = () => {
		const country_id = form.getFieldValue('country_id')
		const country = getCountryById(country_id)
		formData.current.contacts.push({
			id: numericNanoid(),
			phone_country_code: country?.phone_number_code,
			mobile_country_code: country?.phone_number_code,
			language_id: formData.current.customerDetails?.language_id
		})
		if (formData.current.contacts.length === 1) {
			handleContactRoleChange({
				contactId: formData.current.contacts[0].id,
				type: 'primary',
				action: 'add'
			});
		}
		submitLocally()
	}
	const handleResetFields = async () => {
		formData.current.customerDetails = {}
		formData.current.addresses = [{ id: numericNanoid() }]
		formData.current.primaryAddressId = formData.current.addresses[0].id
		formData.current.contacts = [{ id: numericNanoid() }]
		formData.current.primaryContactId = formData.current.contacts[0].id

		await form.resetFields();
		handleCustomerDetailsSubmit(form.getFieldsValue(), form.getFieldsValue())
		submit({
			action: 'resetFields'
		}, {
			method: 'post',
			encType: 'application/json'
		})
	}


	const handleFormSubmit = async () => {
		//Check basic validations
		//const isValid = formValidationStatus.customerDetails && Object.values(add)
		if (Object.values(formData.current.addresses).length == 0) {
			return message.error('Please add atleast one address')
		}
		if (Object.values(formData.current.contacts).length == 0) {
			return message.error('Please add atleast one contact')
		}
		if (!primaryAddressId) {
			addressRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
			return message.error('Please select a primary address')
		}
		if (!primaryContactId) {
			contactref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

			return message.error('Please select a primary contact')
		}

		submit({
			...formData.current,
			action: 'saveCustomer'
		}, {
			method: 'post',
			encType: 'application/json'
		})
	}

	const changeFormsHandler = (formName, { changedFields, forms }) => {
		if (formName !== 'customerDetails' || !changedFields.find(({ name }) => name.includes('country_id'))) {
			return
		}

		const country_id = forms.customerDetails.getFieldValue('country_id')
		const language_id = forms.customerDetails.getFieldValue('language_id')
		const country = getCountryById(country_id)

		//Update contact/addresses accordingly
		for (const fkey in forms) {
			if (fkey.startsWith('contact')) {
				if (forms[fkey].getFieldValue('mobile_country_code') == undefined || forms[fkey].getFieldValue('mobile_number') == undefined) {
					forms[fkey].setFieldValue('mobile_country_code', country?.phone_number_code)
				}
				if (forms[fkey].getFieldValue('phone_country_code') == undefined || forms[fkey].getFieldValue('phone_number') == undefined) {
					forms[fkey].setFieldValue('phone_country_code', country?.phone_number_code)
				}
				forms[fkey].setFieldValue('language_id', language_id)
			}
			else if (fkey.startsWith('address')) {
				forms[fkey].setFieldValue('country_id', country_id)
			}
		}
	}

	return (
		<Page className='customers-detail' title={title} >
			<Form.Provider onFormChange={changeFormsHandler}>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><img src="/icons/headericons/companydetails.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Company Create</>} key="1">
						<Customer
							form={form} /* contactForm= {contactForm} */
							initialValues={
								Object.keys(customerDetails).length == 0 ? defaultCustomerDetails : customerDetails
							}
							onValuesChange={handleCustomerDetailsSubmit}
							getValidationStatus={isValid => formValidationStatus.current.customerDetails = isValid}
							errors={customerErrors} />
					</Panel>
				</Collapse>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><img src="/icons/headericons/address.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Addresses</>} key="1" >
						{addresses.map((address, index) => (
							<SmallSection
								key={address.id}
								actions={address.id !== primaryAddressId ? getAddressActions(address.id, address) : null}
								header={(
									<CustomerAddressRoleSelect
										addressId={address.id}
										isPrimary={address.id === primaryAddressId || addresses.length === 1 && index === 0}
										isDelivery={address.id === deliveryAddressId}
										isInvoice={address.id === invoiceAddressId}
										onChange={handleAddressRoleChange}
									/>
								)}
							>
								<div ref={addressRef} ></div>
								<CustomerAddress index={address.id} initialValues={address} onValuesChange={(_, data) => handleCustomerAddressSubmit(address.id, { ...data, id: address.id })} getValidationStatus={isValid => formValidationStatus.current.addresses[address.id] = isValid} errors={addressErrors?.[index]?.reduce((acc, curr) => Object.assign(acc, curr), {})} />
							</SmallSection>
						))}
						<Button onClick={handleAddNewAddress}>Add New Address</Button>
					</Panel>
				</Collapse>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><img src="/icons/headericons/contacts.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Contacts</>} key="1">
						{contacts.map((contact, index) => (
							<SmallSection
								key={contact.id}
								actions={contact.id !== primaryContactId ? getContactAction(contact.id, contact) : null}
								header={(<CustomerContactRoleSelect
									contactId={contact.id}
									isPrimary={contacts.length === 1 || contact.id === primaryContactId}
									isAuthority={!!contact.is_authority}
									isInvoice={contact.id === invoiceContactId}
									onChange={handleContactRoleChange}
								/>)}
							>
								<div ref={contactref} ></div>
								<CustomerContact
									index={contact.id}
									initialValues={contact}
									allowedLanguages={contactLanguageOptions}
									onValuesChange={(_, data) => handleCustomerContactSubmit(contact.id, { ...data, id: contact.id, is_authority: !!formData.current.contacts?.[index]?.is_authority })}
									getValidationStatus={isValid => formValidationStatus.current.contacts[contact.id] = isValid}
									errors={contactErrors?.[index]?.reduce((acc, curr) => Object.assign(acc, curr), {})}
								/>
							</SmallSection>
						))}
						<Button onClick={handleAddNewContact}>Add New Contact</Button>
					</Panel>
				</Collapse>
			</Form.Provider>
			<div className="actions" style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
				<div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
					<Button onClick={async () => {
						await handleResetFields();
						setTimeout(() => {
							form.resetFields();
						}, 100)
					}
					}
						type="primary" icon={<RedoOutlined />}>Reset Fields</Button>
					<Button onClick={() => {
						if (confirm("Unsaved changes will be lost") == true) {
							navigate(-1)
						}
					}} type="danger" icon={<CloseOutlined />} >Cancel</Button>

				</div>
				<Button onClick={handleFormSubmit}
					// disabled={!isFormValid()} 
					type="primary" icon={<CheckOutlined />}>Create</Button>
			</div>
		</Page>
	)
}
CustomerCreate.propTypes = {
	title: PropTypes.string
}

CustomerCreate.Actions = {
	localUpdate: async ({ data }) => {
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
		return data
	},

	resetFields: async () => {
		localStorage.removeItem(LOCAL_STORAGE_KEY)
		return true

	},


	saveCustomer: async ({ data }) => {
		const { customerDetails, contacts, addresses, primaryContactId, invoiceContactId, primaryAddressId, invoiceAddressId, deliveryAddressId } = data
		const formElementsMap = new Map()
		addresses.forEach(({ id }, i) => formElementsMap.set(`address-${i}`, `address-${id}`))
		contacts.forEach(({ id }, i) => formElementsMap.set(`contact-${i}`, `contact-${id}`))
		try {
			const payload = {
				...customerDetails,
				contacts: contacts.map(c => ({
					...c,
					is_primary: c.id === primaryContactId,
					is_billing: c.id === invoiceContactId,
					is_authority: Boolean(c.is_authority),
					id: undefined
				})),
				addresses: addresses.map(c => ({
					...c,
					is_primary: c.id === primaryAddressId,
					is_billing: c.id === invoiceAddressId,
					is_delivery: c.id === deliveryAddressId,
					id: undefined
				}))
			}
			const { message: msg, data: { id } } = await createCustomer(payload)
			localStorage.removeItem(LOCAL_STORAGE_KEY)
			message.success(msg)
			return redirect(generatePath('/customers/:id', {
				id
			}))
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				let errorFormElement = null
				const formattedErrors = {
					addresses: [],
					contacts: []
				}

				Object.entries(error.errors).forEach(([k, v]) => {
					const k_path = k.split('.')
					if (k_path.length === 3) {
						if (k_path[0] === 'addresses') {
							if (formattedErrors.addresses[k_path[1]] == null) formattedErrors.addresses[k_path[1]] = []
							formattedErrors.addresses[k_path[1]].push({
								[k_path[2]]: v
							})
						}
						else if (k_path[0] === 'contacts') {
							///Send it to vendor_contacts reducer
							if (formattedErrors.contacts[k_path[1]] == null) formattedErrors.contacts[k_path[1]] = []
							formattedErrors.contacts[k_path[1]].push({
								[k_path[2]]: v
							})
						}
					}
					else {
						formattedErrors[k_path] = v
					}
					if (errorFormElement) {
						errorFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
					}
				})

				let errorFormElementId = ''
				const { addresses: addressErrors = [], contacts: contactErrors = [], ...customerErrors } = formattedErrors
				if (Object.keys(customerErrors)?.length > 0) {
					errorFormElementId = 'customerDetails'
				}
				else if (addressErrors.length) {
					const errorIndex = Math.min(...Object.keys(addressErrors))
					errorFormElementId = formElementsMap.get(`address-${errorIndex}`)
				}
				else if (contactErrors.length) {
					const errorIndex = Math.min(...Object.keys(contactErrors))
					errorFormElementId = formElementsMap.get(`contact-${errorIndex}`)
				}
				if (errorFormElementId && document.querySelector(`#${errorFormElementId}`)) {
					document.querySelector(`#${errorFormElementId}`).scrollIntoView({ behavior: 'smooth', block: 'start' })
				}
				return {
					errors: { customerErrors, addressErrors, contactErrors }
				}
			}
		}
		return false
	}
}

CustomerCreate.Loader = async () => {
	const storedData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
	let { addresses, contacts, customerDetails = {}, primaryAddressId, invoiceAddressId, deliveryAddressId, primaryContactId, invoiceContactId } = storedData;

	//Always maintain atleast one address and one contact
	if (!addresses || addresses.length === 0) {
		addresses = [{ id: numericNanoid() }]
		primaryAddressId = addresses[0].id
	}
	if (!contacts || contacts.length === 0) {
		contacts = [{ id: numericNanoid() }]
		primaryContactId = contacts[0].id
	}
	// if (addresses.length > 0 && !primaryAddressId) {
	//     primaryAddressId = addresses[0].id;
	// }
	return {
		customerDetails,
		addresses,
		contacts,
		primaryAddressId,
		invoiceAddressId,
		deliveryAddressId,
		primaryContactId,
		invoiceContactId
	}
}

export default CustomerCreate