
export const ProjectKanabanColumns = [
    { key: 1, title: 'New Request' },
    { key: 3, title: 'Quotation Request Sent' },
    { key: 4, title: 'Quotation sent' },
    { key: 5, title: 'Won - Confirmation client sent' },
    { key: 9, title: 'Closed Lost' },
    { key: 10, title: 'Cancelled' },
    { key: 100, title: 'Cancellation Request' },//Dummy staatus
]

export const ProjectStatuses = Object.freeze({
    NEW_REQUEST: 1,
    QUOTATION_REQUEST_SENT: 3,
    QUOTATION_SENT: 4,
    WON_CONFIRMATION_CLIENT_SENT: 5,
    CLOSED_LOST: 9,
    CANCELLED: 10,
    CANCELLATION_REQUEST: 100, // Dummy status
});

export const ProjectStatusDetailsAuditTrail = {
    [ProjectStatuses.NEW_REQUEST]: "New Request",
    [ProjectStatuses.QUOTATION_REQUEST_SENT]: "Quotation Request Sent",
    [ProjectStatuses.QUOTATION_SENT]: "Quotation Sent",
    [ProjectStatuses.WON_CONFIRMATION_CLIENT_SENT]: "Won - Confirmation Client Sent",
    [ProjectStatuses.CLOSED_LOST]: "Closed Lost",
    [ProjectStatuses.CANCELLED]: "Cancelled",
    [ProjectStatuses.CANCELLATION_REQUEST]: "Cancellation Request", // Dummy status
};
