import { Button, Checkbox, Collapse, Descriptions, Statistic, Table, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useFetcher, useLoaderData, useNavigate } from 'react-router-dom';
import { getEulerProjectDetails, updateEulerDetails } from '../../api/finance';
import Currency from '../../components/inputs/Currency.jsx';
import { FieldWrapper, InputWrapper } from '../../components/inputs/input-wrappers.jsx';
import { Page } from '../../components/page';
import { InvoiceColumnsWithoutActions, generateRouteUrl } from '../../library/constants';
import { ERP_CURRENCY } from '../../library/constants/dynamic.js';
import { convertDataTypes } from '../../library/helpers/index.js';
import { formatPrice } from '../../library/utilities/intl.js';
import styles from './euler-case.module.scss';
const { Panel } = Collapse

const EulerBaseBasicDetailsView = ({ eulerDetails, currency }) => {
    const [isClaimInsured, setIsClaimInsured] = useState(eulerDetails.claim_insured)
    const fetcher = useFetcher()
    const { errors = null } = fetcher.errors || {}

    return (
        <fetcher.Form method="post">
            <Descriptions>
                <Descriptions.Item label="Claim Insured">
                    <Checkbox
                        name='claim_insured'
                        onChange={(e) => { setIsClaimInsured(e.target.checked) }}
                        checked={isClaimInsured}
                        value="1"
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Amount Received From Euler">
                    <FieldWrapper name="amount_received" errors={errors}>
                        <Currency name="amount_received" defaultValue={eulerDetails.amount_received} currency={currency} disabled={!isClaimInsured} />
                    </FieldWrapper>
                </Descriptions.Item>
                <Descriptions.Item label="Case Number">
                    <InputWrapper name="case_number" defaultValue={eulerDetails.case_number} errors={errors} />
                </Descriptions.Item>
                <Descriptions.Item label="Company Name">
                    {eulerDetails.customer.company}
                </Descriptions.Item>
                <Descriptions.Item label="Total Price Inc VAT">
                    {formatPrice(eulerDetails.inc_amount ?? 0, currency)}
                </Descriptions.Item>
                <Descriptions.Item label="Total Euler Excl. VAT">
                    {formatPrice(eulerDetails.euler_inc_amount ?? 0, currency)}
                </Descriptions.Item>
                <Descriptions.Item label="Notes">
                    <InputWrapper name="notes" defaultValue={eulerDetails.notes} errors={errors} textarea={true} />
                </Descriptions.Item>
            </Descriptions>
            <div className="actions">
                <Button htmlType='submit' type='primary' name='action' value='updateEulerDetails'>Update</Button>
            </div>
        </fetcher.Form>
    )
}
EulerBaseBasicDetailsView.propTypes = {
    eulerDetails: PropTypes.object.isRequired,
    currency: PropTypes.oneOf(['EUR', 'USD', 'GBP']),
}

const EulerCaseDetails = ({ title }) => {
    const { eulerDetails } = useLoaderData()
    const navigate = useNavigate()

    return (
        <Page className='projects-create' title={title} >
            <div className='headerStats'>
                <Statistic
                    title="Case Number"
                    formatter={v => v}
                    value={eulerDetails.case_number}
                />
                <Link to={generateRouteUrl('CustomerDetails', {
                    id: eulerDetails.customer_id
                })}>
                    <Statistic
                        title="Customer"
                        value={eulerDetails.customer?.company}
                    />
                </Link>
                <Statistic
                    title="Total Price"
                    value={formatPrice(eulerDetails.euler_inc_amount, ERP_CURRENCY)}
                />
                <Statistic
                    title="Amount Paid"
                    value={formatPrice(eulerDetails.amount_received, ERP_CURRENCY)}
                />

            </div>
            <Collapse defaultActiveKey={['1']} expandIconPosition="end" >
            <Panel header={<><img src="/icons/headericons/eulercases.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Euler Case</>} key="1" >
                    <EulerBaseBasicDetailsView eulerDetails={eulerDetails} currency={ERP_CURRENCY} />
                </Panel>
            </Collapse>
            <Collapse defaultActiveKey={['1']} expandIconPosition="end" >
            <Panel header={<><img src="/icons/headericons/invoices.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Invoices</>} key="1" >
                    <Table
                        className={styles.table}
                        columns={InvoiceColumnsWithoutActions}
                        dataSource={[eulerDetails.invoice]}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                        rowKey='id'
                        // scroll={{ x: 'max-content' }}
                        onRow={(record) => ({
                            onClick: () => {
                                navigate(generateRouteUrl('InvoiceDetails', {
                                    invoiceId: record.id
                                }))
                            }
                        })}
                    />
                </Panel>
            </Collapse>
        </Page >
    )
}

EulerCaseDetails.propTypes = {
    title: PropTypes.string
}

EulerCaseDetails.Actions = {
    updateEulerDetails: async ({ params, data }) => {
        const { id } = params
        try {
            const payload = convertDataTypes(data, {
                claim_insured: v => parseInt(v) ? 1 : 0,
                amount_received: parseFloat,
            })
            const msg = await updateEulerDetails(id, payload)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
        }
        return false
    },
}

EulerCaseDetails.Loader = async ({ params }) => {
    const eulerDetails = await getEulerProjectDetails(params.id)
    if (!eulerDetails) {
        throw new Error('Invalid Euler Case')
    }

    return {
        eulerDetails
    }
}

export default EulerCaseDetails