import { Collapse, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { getProjectDetails } from '../../../api/project';
import { createProjectItem } from '../../../api/project-item';
import { ProjectItem } from '../../../components/form';
import { Page } from '../../../components/page';
import { generateRouteUrl } from '../../../library/constants/routes.js';

const { Panel } = Collapse

const ProjectItemCreate = ({ title }) => {
	const { projectId } = useParams()
	const { language } = useLoaderData()
	const itemDetailsForm = useRef({
		isValid: false,
		data: {}
	})
	const navigate = useNavigate()

	const submitForm = async () => {
		if (!itemDetailsForm.current.isValid) {
			return
		}
		try {
			const response = await createProjectItem(projectId, { ...itemDetailsForm.current.data })
			const { message: msg, data } = response
			message.success(msg)
			navigate(generateRouteUrl('ProjectItemUpdate', {
				projectId: projectId,
				itemId: data.id,
			}))
		}
		catch (e) {
			message.error(e.message)
			throw e
		}
	}

	const handleItemChange = async (changed, values) => {
		itemDetailsForm.current.data = { ...values }
		submitForm()
	}

	return (
		<Page className='projects-item-create' title={title} >
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				alignTtems: 'stretch',
			}} >
				<h3>Project ID :{projectId}</h3>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
				<Panel header={<><img src='/icons/headericons/iteminformation.svg'/>Item Information</>} key="1">
						<ProjectItem
							initialValues={{}}
							getValidationStatus={(isValid) => { itemDetailsForm.current.isValid = isValid; submitForm() }}
							onValuesChange={handleItemChange}
							language={language}
						/>
					</Panel>
				</Collapse>
			</div>
		</Page>
	)
}

ProjectItemCreate.propTypes = {
	title: PropTypes.string.isRequired
}

ProjectItemCreate.Loader = async ({ params }) => {
	const { projectId } = params
	const projectDetails = await getProjectDetails(projectId)
	return { language: projectDetails.language }
}


export default ProjectItemCreate