import { SendOutlined } from '@ant-design/icons';
import { Button, message } from "antd";
import PropTypes from 'prop-types';
import React, { useRef } from "react";
import { uploadFileFromObject } from "../../../api/common";
// import { sendArtworktoVendor } from "../../../api/order";
import { productUrl } from '../../../library/helpers/product_url.js';
import { AddFileUpload } from "../../../ui";
import styles from './order-manager-studio.module.scss';

const OrderManagerStudio = ({ orderDetails, description, languageCode, actionText, loading, onSubmit }) => {
    const fileUploaderRef = useRef([])
    const url = orderDetails?.product?.product_link ? productUrl(orderDetails.product.product_link, languageCode) : '';

    const handleFilesUpload = async () => {
        if (fileUploaderRef.current.length === 0) {
            alert('Please select atleast one file')
            return
        }

        const promises = fileUploaderRef.current.map(({ file }) => uploadFileFromObject(file))
        try {
            const fileIds = await Promise.all(promises)
            const imageData = fileIds.map((id, index) => ({
                id,
                comment: fileUploaderRef.current[index].comment
            }))
            onSubmit(imageData)
        }
        catch (error) {
            message.error(error.message)
        }
    }
    return (
        <div>
            <div className={styles.tabContainer} >
                {!!description && <>{description}<br /><br /></>}
                <p>Product Link from vendor</p>
                <a href={url} target='_blank' rel="noreferrer" style={{ color: '#1491ff' }}>{url}</a>
                <p style={{ 'marginTop': '16px', 'marginBottom': '16px' }}>Specifications : </p>
                {/* Todo: @ahmad show specs information here */}
            </div>
            <br />
            <div>
                <AddFileUpload ref={fileUploaderRef} acceptedFileType={['png', 'jpg', 'pdf', 'esp', 'psd', 'ai', 'tiff', 'svg']} />
            </div>
            <div className={styles.sendButton}>
                <Button icon={<SendOutlined />} onClick={handleFilesUpload} loading={loading}>{actionText}</Button>
            </div>
        </div>
    )
}
OrderManagerStudio.propTypes = {
    orderDetails: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    description: PropTypes.string,
    actionText: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    languageCode: PropTypes.string.isRequired
}
export default OrderManagerStudio