import {
    FormOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Collapse, Select, message } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Form, Link, replace, useActionData, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { addPurchaseBillLine, deletePurchaseBillLine, updatePurchaseBillDetails, updatePurchaseBillLine } from '../../api/finance';
import { SmallSection } from '../../components/design';
import { CurrencySelectDropDown, DatePicker, ExecutiveEntitySelect, FieldWrapper, GeneralFieldWrapper, InputWrapper } from '../../components/inputs';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { generateRouteUrl } from '../../library/constants';
import PaymentMethods from '../../library/dynamics/payment-method';
import { convertDataTypes, getEntityById, getFormattedId } from '../../library/helpers/index.js';
import { formDataToJsonV2 } from '../../library/utilities/formdata.js';
import { formatDate } from '../../library/utilities/intl.js';
import { PurchaseBillLines } from './purchase-bill-lines.jsx';
import Styles from './purchasebills-basic-details.module.scss';


const { Panel } = Collapse
moment.locale('nl')

const PurchaseBillBasicDetails = () => {
    const { purchaseBillId, purchaseBillDetails: data, purchaseBillLines } = useRouteLoaderData('FinancePurchaseBillDetails')

    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header={<><img src="/icons/headericons/invoicedetail.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Invoice Details</>} key="1" extra={(
                <div onClick={(event) => event.stopPropagation()}>
                    <Link to={generateRouteUrl('UpdatePurchaseBillBasicDetails', {
                        purchaseBillId,
                    })} replace ><FormOutlined /></Link>
                </div>
            )}>
                <div className={Styles.mainWrapper}>
                    <SmallSection colorDark='#8F8F8F'>
                        <div className={Styles.fieldsWrapper}>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Project Id</div>
                                <div className={Styles.DescriptionValue}><Link to={generateRouteUrl('FinanceProjectDetails', {
                                    projectId: data.project_id
                                })}>{getFormattedId(data.project_id)}</Link></div>
                            </div>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Invoice <br /> (Payment Ref)</div>
                                <div className={Styles.DescriptionValue}>{data.invoice_number}</div>
                            </div>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Supplier</div>
                                <div className={Styles.DescriptionValue}>
                                    <Link to={generateRouteUrl('VendorUpdate', {
                                        id: data.vendor_id
                                    })}>{data?.vendor?.company}</Link>
                                </div>
                            </div>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Entity</div>
                                <div className={Styles.DescriptionValue}>{data?.entity?.name}</div>
                            </div>
                        </div>
                    </SmallSection>
                    <SmallSection colorDark='#8F8F8F'>
                        <div className={Styles.fieldsWrapper}>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Invoice Date</div>
                                <div className={Styles.DescriptionValue}>{formatDate(data.invoice_date)}</div>
                            </div>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Due Date</div>
                                <div className={Styles.DescriptionValue}>{formatDate(data.due_date)}</div>
                            </div>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Currency</div>
                                <div className={Styles.DescriptionValue}>{data.currency?.name}</div>
                            </div>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Payment Method</div>
                                <div className={Styles.DescriptionValue}>{PaymentMethods.find(({ id }) => id === data.payment_method_id)?.name}</div>
                            </div>
                        </div>
                    </SmallSection>
                    <SmallSection colorDark='#8F8F8F'>
                        <div className={Styles.fieldsWrapper}>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Pass on ?</div>
                                <div className={Styles.DescriptionValue}>{(data.pass_on && data.pass_on_entity_id) ? getEntityById(data.pass_on_entity_id)?.name : 'No'}</div>
                            </div>
                            <div className={Styles['description--span2']}>
                                <div className={Styles.DescriptionTitle}>Propforma</div>
                                <div className={Styles.DescriptionValue}>{data.proforma ? 'Yes' : 'No'}</div>
                            </div>
                        </div>
                    </SmallSection>
                    <SmallSection colorDark='#8F8F8F'>
                        <div className={Styles.fieldsWrapper}>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Description</div>
                                <div className={Styles.DescriptionValue}>{data.description}</div>
                            </div>
                        </div>
                    </SmallSection>
                </div>
                <div className={Styles.mainWrapper}>
                    <PurchaseBillLines edit={false} lines={purchaseBillLines} currency={data.currency} entity_id={data.entity_id} />
                </div>
            </Panel>
        </Collapse>
    )
}
PurchaseBillBasicDetails.Actions = {
    addLine: async ({ params, data }) => {
        const payload = convertDataTypes(data, {
            // general_ledger_id: parseInt,
            quantity: parseInt,
            vat_type_id: parseInt,
            price_excl_vat: Number
        })
        try {
            const msg = await addPurchaseBillLine(params.purchaseBillId, payload)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },
    deleteLine: async ({ params, data }) => {
        const { lineId } = data
        try {
            const msg = await deletePurchaseBillLine(params.purchaseBillId, lineId)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
        }
        return false
    },
}

const UpdatePurchaseBillBasicDetails = () => {
    const { purchaseBillDetails, purchaseBillLines } = useRouteLoaderData('FinancePurchaseBillDetails')
    const navigate = useNavigate()
    const [paymentMethodId, setPaymentMethodId] = useState(purchaseBillDetails.payment_method_id)
    const [passOn, setPassOn] = useState(!!purchaseBillDetails.pass_on)
    const { errors = null } = useActionData() || {};

    return (
        <>
            <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header={<><img src="/icons/headericons/invoicedetail.svg" style={{ width: '1.25rem', verticalAlign: 'sub', marginRight: '8px' }} />Invoice Details</>} key="1">
                    <Form id="formFill" method="post" className={Styles['fieldsWrapper--Four']}>
                        <input type="hidden" name="action" value="updateDetails" />
                        <InputWrapper
                            name="invoice_number"
                            defaultValue={purchaseBillDetails.invoice_number}
                            label="Invoice (Payment Reference)"
                            errors={errors}
                            required
                        />
                        <FieldWrapper name="entity_id" label="Entity" errors={errors}>
                            <ExecutiveEntitySelect name="entity_id" value={purchaseBillDetails.entity_id} />
                        </FieldWrapper>
                        <FieldWrapper name="invoice_date" label="Invoice Date" errors={errors}>
                            <DatePicker
                                name="invoice_date"
                                value={purchaseBillDetails.invoice_date}
                                useHidden
                                disabledBefore={dayjs().subtract(2, 'day').format('YYYY-MM-DD')}
                            />
                        </FieldWrapper>
                        <FieldWrapper name="due_date" label="Due Date" errors={errors}>
                            <DatePicker
                                name="due_date"
                                value={purchaseBillDetails.due_date}
                                useHidden
                            />
                        </FieldWrapper>
                        <FieldWrapper name="currency_id" label="Currency" errors={errors}>
                            <CurrencySelectDropDown value={purchaseBillDetails.currency_id} disabled />
                        </FieldWrapper>
                        <FieldWrapper name="payment_method_id" label="Payment method" errors={errors}>
                            <Select
                                options={PaymentMethods}
                                value={paymentMethodId}
                                onChange={(v) => {
                                    setPaymentMethodId(v)
                                }}
                                fieldNames={{ value: 'id', label: 'name' }}
                            />
                            <input type="hidden" name="payment_method_id" value={paymentMethodId} />
                        </FieldWrapper>
                        <GeneralFieldWrapper style={{ display: 'grid', gridTemplateColumns: 'max-content 1fr', gap: '1rem' }}>
                            <FieldWrapper name="pass_on" label="Pass on ?" errors={errors}>
                                <Checkbox name="pass_on" defaultChecked={!!purchaseBillDetails.pass_on} onChange={e => setPassOn(e.target.checked)} />
                                <input type="hidden" name="pass_on" value={passOn} />
                            </FieldWrapper>
                            <FieldWrapper name="pass_on_entity_id" label="Pass on Entity" errors={errors}>
                                <ExecutiveEntitySelect name="pass_on_entity_id" defaultValue={purchaseBillDetails.pass_on_entity_id} disabled={!passOn} />
                            </FieldWrapper>
                        </GeneralFieldWrapper>
                        <FieldWrapper name="proforma" label="Proforma" errors={errors}>
                            <input type="hidden" name="proforma" value={false} />
                            <Checkbox name="proforma" defaultChecked={purchaseBillDetails.proforma} valuePropName="checked" />
                        </FieldWrapper>
                        <InputWrapper
                            name="description"
                            label="Description"
                            defaultValue={purchaseBillDetails.description}
                            span={4}
                            // defaultValue={item ?? undefined}
                            textarea
                        />

                        <div className={Styles.mainWrapper_bill}>
                            <PurchaseBillLines edit={true} lines={purchaseBillLines} currency={purchaseBillDetails.currency} entity_id={purchaseBillDetails.entity_id} errors={errors?.purchaseLine} />
                        </div>
                        <div className={Styles.actionButton}>
                            <Button type="primary" htmlType='submit'>Save</Button>
                            <Button onClick={() => navigate('..', { replace: true })}>Cancel</Button>
                        </div>
                    </Form>
                </Panel>
            </Collapse>
        </>
    )
}

UpdatePurchaseBillBasicDetails.Actions = {
    updateLine: async ({ params, data }) => {
        console.log({ data })
        const { line_id, ...payload } = convertDataTypes(data, {
            line_id: parseInt,
            // general_ledger_id: parseInt,
            quantity: parseInt,
            vat_type_id: parseInt,
            price_excl_vat: Number
        })
        try {
            const msg = await updatePurchaseBillLine(params.purchaseBillId, line_id, payload)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },

    updateDetails: async ({ params, data }) => {
        const payload = convertDataTypes(formDataToJsonV2(data), {
            entity_id: parseInt,
            pass_on_entity_id: parseInt,
            payment_method_id: parseInt,
            pass_on: (val) => val === 'true' ? 1 : 0,
            proforma: (val) => val === 'true' ? 1 : 0,
            purchaseLine: (lines) => Object.values(lines).map(line => convertDataTypes(line, ({
                id: parseInt,
                price_excl_vat: parseFloat,
                quantity: parseInt,
                vat_type_id: parseInt,
                general_ledger_id: parseInt,
            })))
        })

        try {
            const msg = await updatePurchaseBillDetails(params.purchaseBillId, payload)
            message.success(msg)
            return replace(generateRouteUrl('FinancePurchaseBillDetails', {
                purchaseBillId: params.purchaseBillId
            }))
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.getNestedErrors()
                }
            }
        }
        return false
    },

}

export { PurchaseBillBasicDetails, UpdatePurchaseBillBasicDetails };

