import { ExportOutlined } from '@ant-design/icons';
import {
	Input,
	Radio,
	Select
} from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ValidationForm as Form } from '../';
import { checkCustomerDuplicacy } from '../../../api/customer/create-customer.js';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import InvoiceTypes from '../../../library/dynamics/invoice-types';
import PaymentTerms from '../../../library/dynamics/payment-terms';
import { getCountryById } from '../../../library/helpers/country.js';
import { getCurrencyById } from '../../../library/helpers/currency.js';
import { DEFAULT_ENTITY_ID, getEntityById } from '../../../library/helpers/entity.js';
import { getIndustryById } from '../../../library/helpers/industries.js';
import { getInvoiceTypeById } from '../../../library/helpers/invoice-types.js';
import { getLanguageById } from '../../../library/helpers/language.js';
import { normalizeEmail, normalizeWebsite } from '../../../library/helpers/normalize';
import { getPaytmentTermById } from '../../../library/helpers/payment-terms.js';
import { fetchWebsiteLogo } from '../../design';
import { CountrySelect, CurrencySelect, ExecutiveEntitySelect, GeneralFieldWrapper, IndustrySelect, LanguageSelect, PhoneInput } from '../../inputs';
import PhoneDisplay from '../../inputs/PhoneDisplay.jsx';
import styles from './customer.module.scss';


const CustomerDisabled = ({ data = {} }) => {
	const country = getCountryById(data.country_id)
	const entity = getEntityById(data.entity_id)
	const currency = getCurrencyById(data.currency_id)
	const language = getLanguageById(data.language_id)
	const industry = getIndustryById(data.industry_id)
	const paymentTerm = getPaytmentTermById(data.payment_term_id)
	const invoiceType = getInvoiceTypeById(data.invoice_type_id)

	return (
		<div className="form-fields-wrapper form-fields-wrapper--five" style={{ alignItems: 'start' }}>
			<div style={{ gridRow: 'span 2' }}>
				{fetchWebsiteLogo(data.website)}
			</div>
			<GeneralFieldWrapper label="Bad Payment History" style={{ gridColumn: 'span 3' }}>{data.bad_payment_history ? "Yes" : 'No'} </GeneralFieldWrapper>
			<GeneralFieldWrapper label="Company" style={{ gridColumn: 'span 2' }}>{data.company}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Country">{country?.name}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Executive Entity">{entity?.name}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Phone Number"><PhoneDisplay code={data.contact_country_code} number={data.contact_number} /></GeneralFieldWrapper>
			<GeneralFieldWrapper label="Email">{data.email}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Website">{data.website}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Currency">{currency?.name}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Language">{language?.name}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Industry">{industry?.name}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Payment Term">{paymentTerm?.name}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="VAT Number">{data.vat_number}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Invoice Type">{invoiceType?.name}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Internal Notes" style={{ gridColumn: 'span 3' }}>{data.internal_notes}</GeneralFieldWrapper>
			<GeneralFieldWrapper label="Payment Notes" style={{ gridColumn: 'span 3' }}>{data.payment_notes}</GeneralFieldWrapper>
		</div>
	)
}
CustomerDisabled.propTypes = {
	data: PropTypes.object.isRequired
}

const duplicacyChecker = async (field, value, originalValue) => {
	//Don't check on update form when value is unchanged
	if (originalValue && value === originalValue) {
		return Promise.resolve()
	}
	const data = await checkCustomerDuplicacy(field, value)
	if (data && data.id) {
		return Promise.reject(<>{field} is already being used <a className="blue" href={generateRouteUrl('CustomerDetails', {
			id: data.id
		})} target="_blank" rel="noopener noreferrer" ><ExportOutlined /> View</a></>)
	}
	else {
		return Promise.resolve()
	}
}

const getEnitySupportedLanguages = (entityId) => getEntityById(entityId)?.default_language ?? []

const Customer = ({ children, getValidationStatus, form, ...otherProps }) => {
	const website = otherProps?.initialValues?.website
	const [allowedLanguages, setAllowedLanguages] = useState(otherProps?.initialValues?.entity_id ? getEnitySupportedLanguages(otherProps.initialValues.entity_id) : [])
	const isNewRecord = !otherProps?.initialValues?.id

	const CustomerValidationRules = {
		company: [
			{
				required: true,
				message: 'Company is required'
			},
			{
				validator: ({ field }, value) => duplicacyChecker(field, value, !isNewRecord ? otherProps?.initialValues?.[field] : null),
			}
		],
		payment_term_id: [
			{
				required: true,
				message: 'Payment Term is required'
			}
		],
		country_id: [
			{
				required: true,
				message: 'Country is required'

			}
		],
		language_id: [
			{
				required: true,
				message: 'Language is required'
			}
		],
		// contact_country_code: [
		// 	{
		// 		required: true,
		// 	}
		// ],
		// contact_number: [
		// 	{
		// 		required: true,
		// 	}
		// ],
		email: [
			{
				required: true,
				message: 'Email is required'
			},
			{
				type: 'email',
				message: 'Email is not valid'
			},
			{
				validator: ({ field }, value) => duplicacyChecker(field, value, !isNewRecord ? otherProps?.initialValues?.[field] : null),
				// validateTrigger: 'onBlur'
			}
		],
		currency_id: [
			{
				required: true,
				message: 'Currency is required'
			}
		],
		entity_id: [
			{
				required: true,
				message: 'Entity is required'
			}
		],
	}

	// useEffect(() => {
	// 	console.log({ allowedLanguages })
	// }, [allowedLanguages])

	if (otherProps.disabled) {
		return (
			<CustomerDisabled data={otherProps?.initialValues} />
		)
	}

	return (
		<Form form={form} name='customerDetails' onValidationStatusChange={getValidationStatus ?? undefined} layout="vertical" validateTrigger={['onChange', 'onBlur']} className={styles.FormWrapper} validationRules={CustomerValidationRules} {...otherProps}>
			<Form.Item style={{ gridRow: 'span 2' }}>
				{fetchWebsiteLogo(website)}
			</Form.Item>
			<Form.Item name='bad_payment_history' style={{ gridColumn: 'span 2' }}>
				<div className={styles.paymenthistory}>
					<h4>Payment history</h4>
					<Radio.Group defaultValue={otherProps.initialValues.bad_payment_history == 1 ? 1 : 0} className='toggle-token' >
						<Radio value={1}><div className={styles.badPaymentHistory}>Bad Payment History</div></Radio>
						<Radio value={0}>None</Radio>
					</Radio.Group>
				</div>
			</Form.Item>
			<Form.Item name="company" label="Company" className={styles['FormField--TwoCol']} >
				<Input />
			</Form.Item>
			<Form.Item name='country_id' label="Country">
				<CountrySelect
					onChange={(_, country) => {
						let _entityLanguages = [...allowedLanguages]
						if (country.default_entity_id) {
							_entityLanguages = getEnitySupportedLanguages(country.default_entity_id)
							setAllowedLanguages(_entityLanguages)
						}

						form.setFieldsValue({
							currency_id: country.currency_id ?? null,
							contact_country_code: country.phone_number_code,
							entity_id: country.default_entity_id ?? DEFAULT_ENTITY_ID,
						})
						const default_language_id = country.default_language_id
						if (default_language_id && _entityLanguages.includes(default_language_id)) {
							form.setFieldValue('language_id', default_language_id)
						}
					}}
				/>
			</Form.Item>
			<Form.Item name='entity_id' label="Executive Entity" dependencies={['country_id']}>
				<ExecutiveEntitySelect onChange={(entityId, entity) => {
					const _entityLanguages = getEnitySupportedLanguages(entityId)
					setAllowedLanguages(_entityLanguages)
					// Check if language is already selected
					const language_id = form.getFieldValue('language_id')
					if (!language_id || !_entityLanguages.includes(language_id)) {
						form.setFieldValue('language_id', entity.default_language_id)
					}
				}} />
			</Form.Item>
			<Form.Item label="Phone Number" required>
				<PhoneInput valuePropName={{ code: 'contact_country_code', number: 'contact_number' }} dependencies={['country_id']} label='Phone Number' required />
			</Form.Item>
			<Form.Item normalize={normalizeEmail} name="email" label="Email" onChange={() => {
				form.validateFields(['email']).then(({ email }) => {
					form.setFieldValue('website', email.split('@').pop())
				}
				).catch(() => { })
			}} >
				<Input type="email" />
			</Form.Item>
			<Form.Item normalize={normalizeWebsite} name="website" label="Website">
				<Input type="text" />
			</Form.Item>
			<Form.Item name='currency_id' label="Currency" dependencies={['country_id']} >
				<CurrencySelect />
			</Form.Item>
			<Form.Item name='language_id' label="Language" dependencies={['country_id']} >
				<LanguageSelect options={[...new Set([...allowedLanguages, otherProps?.initialValues?.language_id])]} />
			</Form.Item>

			<Form.Item name='industry_id' label="Industry">
				<IndustrySelect />
				{/* <Select options={Industries} fieldNames={{ value: 'id', label: 'name' }} /> */}
			</Form.Item>
			<Form.Item name='payment_term_id' label="Payment Term" >
				<Select options={PaymentTerms} fieldNames={{ value: 'id', label: 'name' }} onChange={(e) => console.log(e)} />
			</Form.Item>
			<Form.Item name='vat_number' label="VAT Number">
				<Input />
			</Form.Item>
			<Form.Item name='invoice_type_id'
				label="Invoice Type">
				<Radio.Group optionType="button"
					buttonStyle="solid"
				>
					{
						InvoiceTypes.map(({ id, name }) => {
							return <Radio autoFocus={true} value={id} key={id}>
								{name}
							</Radio>
						})
					}

				</Radio.Group>
				{/* <Radio.Group

					buttonStyle="solid"
					optionType="button"
					//defaultValue={otherProps.initialValues == {} ? 'email' : 'post'}
					options={InvoiceTypes.map(({ id, name }) => ({ value: id, label: name }))}
					fieldNames={{ value: 'id', label: 'name' }}
				/> */}

			</Form.Item>
			{/* <Form.Item name={['hubspot_identifier']} label="Hubspot Identifier">
				<Input disabled />
			</Form.Item> */}
			<Form.Item name='internal_notes' label="Internal Notes" className={styles['FormField--FullCol']}>
				<Input.TextArea rows={4} />
			</Form.Item>
			<Form.Item name='payment_notes' label="Payment Notes" className={styles['FormField--FullCol']}>
				<Input.TextArea rows={4} />
			</Form.Item>
			{children}
		</Form >
	)
}

Customer.propTypes = {
	children: PropTypes.node,
	onSubmit: PropTypes.func,
	getValidationStatus: PropTypes.func,
	form: PropTypes.object,
	contactForm: PropTypes.object,
}

export default Customer