import { Alert, Button } from 'antd';
import React from "react";
import { Link, useRouteLoaderData } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './send-template.module.scss';

const SendTemplate = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')

    return (
        <div className={style.container}>
            <h3>Task for you</h3>
            {<Alert message="Template file has been sent to customer for review" type="info" />}
            <Alert message="Customer has not shared any artwork yet." type="info"
                action={<Link to={generateRouteUrl('MakeCustomerArtwork', {
                    itemId: orderDetails.id,
                })}>
                    <Button className={style.greenButton}>Make Digital Proof</Button>
                </Link>}
            />
        </div>
    )

}
export default SendTemplate;