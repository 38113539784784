import { CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Empty, InputNumber, Segmented, Space } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useFetcher } from 'react-router-dom';
import SmallSection from '../../../components/design/small-section.jsx';
import AddressSelect from '../../../components/inputs/AddressSelect.jsx';
import Currency from '../../../components/inputs/Currency.jsx';
import { FieldWrapper, GeneralFieldWrapper, InputWrapper } from '../../../components/inputs/input-wrappers.jsx';
// import { generateRouteUrl } from '../../../library/constants/routes.js';
import { Box } from '../../../components/design/box.js';
import Confirm from '../../../components/message/Confirm.jsx';
import { defaultProductMargin } from '../../../library/constants/defaults.js';
import { ERP_CURRENCY } from '../../../library/constants/dynamic.js';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { formDataToNestedJson } from '../../../library/utilities/formdata.js';
import { getFilePath } from '../../../library/utilities/urls.js';
import deliveryStyles from './project-item-update.delivery.module.scss';

const getFormattedAddress = (address) => {
    const lines = [
        address.street,
        address.street_number,
        address.zipcode,
        address.county,
        address.country.name,
    ]
    return lines.filter(v => !!v).join(', ')
}
const DeliveryAddressRow = ({ addressRow, currency, customerId, selectedAddressIds = [], onDelete = null, onChange, disabled }) => {
    const fetcher = useFetcher()
    const deleteFetcher = useFetcher()
    const { errors = null } = fetcher.data || {}

    // const isLoading = fetcher.state === 'submitting'

    const optimisticData = {
        ...addressRow,
        ...(addressRow.margin == null && { margin: defaultProductMargin, }) //== checks null as well as undefined
        // quantity: parseInt(fetcher.formData?.get('quantity')) ?? null,
        // address_id: parseInt(fetcher.formData?.get('address_id')) ?? null,
        // additional_cost: parseFloat(fetcher.formData?.get('additional_cost')),
        // margin: parseFloat(fetcher.formData?.get('margin')),
    }

    const handleSubmit = (form) => {
        const newData = convertDataTypes(formDataToNestedJson(new FormData(form)), {
            additional_cost: parseFloat,
            address_id: parseInt,
            deliveryId: parseInt,
            margin: parseFloat,
            quantity: parseInt,
            sales_price: parseFloat,
        })
        onChange?.(newData)
        fetcher.submit(form, { method: 'post' })
    }
    const debounceSubmit = useCallback(debounce(handleSubmit, 500), []);

    const handleRowDelete = (id) => {
        if (id) {
            deleteFetcher.submit({
                action: 'removeDeliveryAddress',
                deliveryId: id
            }, {
                method: 'post',
                encType: 'application/json'
            })
        }
        if (onDelete) {
            onDelete(id)
        }
    }

    return (
        <fetcher.Form onChange={(e) => {
            //Ignore nested forms change events (inside address select form)
            if (!e.currentTarget.contains(e.target)) {
                return false
            }
            debounceSubmit(e.currentTarget)
        }}>
            <input type="hidden" name="action" value={optimisticData.id ? 'updateDeliveryAddress' : 'addDeliveryAddress'} />
            {!!optimisticData.id && <input type="hidden" name="deliveryId" value={optimisticData.id} />}
            {/* <div className={`${isLoading ? deliveryStyles.deliveryRowLoading : deliveryStyles.deliveryRow}`}> */}
            <div className={deliveryStyles.deliveryRow}>
                <FieldWrapper name="quantity" errors={errors}>
                    <InputNumber key="quantity" name="quantity" defaultValue={optimisticData.quantity} controls={false} keyboard={false} disabled={disabled} />
                </FieldWrapper>
                <FieldWrapper name="address_id" errors={errors}>
                    <AddressSelect
                        name="address_id"
                        customerId={customerId}
                        onSelect={() => { }}
                        defaultValue={optimisticData.address_id}
                        excludeAddressIds={selectedAddressIds}
                        renderSelected={(address) => (
                            <div className={`ant-input dropdown ${disabled ? 'ant-input-disabled' : ''}`}>{address ? getFormattedAddress(address) : 'Select One'}</div>
                        )}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper name="additional_cost" errors={errors}>
                    <Currency key="additional_cost" name="additional_cost" currency={currency} defaultValue={optimisticData.additional_cost ?? null} controls={false} disabled={disabled} />
                </FieldWrapper>
                <FieldWrapper name="margin" errors={errors}>
                    <InputNumber
                        key="margin"
                        name="margin"
                        defaultValue={optimisticData.margin}
                        className='inputfield--percentage'
                        controls={false}
                        suffix="%"
                        step="0.01"
                        precision="2"
                        min={0}
                        onFocus={event => event.target.select()}
                        max={'10000000000'}
                        disabled={disabled}
                    />
                </FieldWrapper>
                <FieldWrapper name="sales_price" errors={errors}>
                    <Currency key="sales_price" name="sales_price" currency={currency} value={optimisticData.sales_price} disabled controls={false} />
                </FieldWrapper>
                <Confirm
                    content="Are you sure you want to remove this delivery address ?"
                    onConfirm={() => handleRowDelete(optimisticData?.id)}
                    icon={<DeleteOutlined />}
                    loading={deleteFetcher.state !== 'idle'}
                    shape="square"
                    type="actionpanel"
                    disabled={disabled}
                    danger
                    ghost
                />
            </div>
        </fetcher.Form>
    )
}
DeliveryAddressRow.propTypes = {
    deliveryId: PropTypes.number,
    addressRow: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
    currency: PropTypes.oneOf(['USD', 'GBP', 'EUR']).isRequired,
    selectedAddressIds: PropTypes.arrayOf(PropTypes.number),
    onAfterSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

const NormalDeliveryAddresses = ({ deliveryAddresses, customerId, orderQuantity, disabled }) => {
    const addNewfetcher = useFetcher()
    // const [totalQuantity, setTotalQuantity] = useState(deliveryAddresses?.reduce(({ quantity }, acc) => quantity + acc, 0)) 
    const [addressQuantities, setAddressQuantities] = useState(
        deliveryAddresses?.reduce((acc, { address_id, quantity }) => {
            acc[address_id] = quantity;
            return acc;
        }, {})
    );
    const sortedDeliveryAddresses = useMemo(() => deliveryAddresses?.filter(({ type }) => type !== 3)?.sort((a, b) => a.id - b.id), [deliveryAddresses])
    const totalQuantity = useMemo(() => Object.values(addressQuantities)?.reduce((acc, q) => q + acc, 0), [addressQuantities])
    const isNewLoading = addNewfetcher.formData?.get('action') === 'addDeliveryAddress'

    if (!sortedDeliveryAddresses || sortedDeliveryAddresses.length === 0) {
        return (
            <>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No delivery addresses added yet" />
                <div className="actions align--right">
                    <AddressSelect
                        name="address_id"
                        customerId={customerId}
                        onSelect={(aid) => {
                            addNewfetcher.submit({
                                address_id: aid,
                                action: 'addDeliveryAddress'
                            }, {
                                method: 'post',
                            })
                        }}
                        excludeAddressIds={deliveryAddresses.map(({ address_id }) => address_id)}
                        renderSelected={() => (
                            <Button loading={isNewLoading} disabled={disabled}>Add Delivery</Button>
                        )}
                        disabled={disabled || isNewLoading}
                    />
                </div>
            </>
        )
    }

    return (
        <>
            {orderQuantity > 0 && totalQuantity !== orderQuantity && (
                <Alert
                    type="warning"
                    message={(totalQuantity - orderQuantity) > 0 ? "Total quantity exceeds order quantity" : "Total quantity is less then order quantity"}
                    showIcon
                />
            )}
            <br />
            <div className={deliveryStyles.deliveryRowHead}>
                <div>Quantity</div>
                <div>Delivery Address</div>
                <div>Delivery Cost</div>
                <div>Margin</div>
                <div>Total Price</div>
                <div></div>
            </div>
            {sortedDeliveryAddresses.map((address) => (
                <SmallSection key={address.id}>
                    <DeliveryAddressRow
                        addressRow={address}
                        currency={ERP_CURRENCY}
                        customerId={customerId}
                        selectedAddressIds={deliveryAddresses.map(({ address_id }) => address_id)} disabled={disabled}
                        onChange={({ address_id, quantity }) =>
                            setAddressQuantities((prevAQ) => ({
                                ...prevAQ,
                                [address_id]: quantity,
                            }))
                        }
                    />
                </SmallSection>
            ))}
            <div className="actions align--right">
                <AddressSelect
                    name="address_id"
                    customerId={customerId}
                    onSelect={(aid) => {
                        addNewfetcher.submit({
                            address_id: aid,
                            action: 'addDeliveryAddress'
                        }, {
                            method: 'post',
                        })
                    }}
                    excludeAddressIds={deliveryAddresses.map(({ address_id }) => address_id)}
                    renderSelected={() => (
                        <Button loading={isNewLoading} disabled={disabled}>Add Delivery</Button>
                    )}
                    disabled={disabled || isNewLoading}
                />
            </div>
        </>
    )
}
NormalDeliveryAddresses.propTypes = {
    deliveryAddresses: PropTypes.arrayOf(PropTypes.object).isRequired,
    customerId: PropTypes.number.isRequired,
    orderQuantity: PropTypes.number,
    disabled: PropTypes.bool,
}

const BulkDeliveryAddresses = ({ bulkAddressData, projectId }) => {
    // const { itemDetails, currency, project } = useLoaderData()
    const fetcher = useFetcher()
    const deleteFetcher = useFetcher()
    const { errors = null } = fetcher.data || {}

    return (
        <fetcher.Form method="post" encType='multipart/form-data'>
            <SmallSection header={
                <div className={deliveryStyles.deliveryRowHead} style={{ width: '100%' }}>
                    <div>File</div>
                    <div>Notes</div>
                    <div>Cost</div>
                    <div>Margin</div>
                    <div>Total Price</div>
                    <div>Download File</div>
                </div>
            }>
                <div className={deliveryStyles.deliveryRow}>
                    <FieldWrapper name="file_id" errors={errors} >
                        <input type="file" accept=".xls,.xlsx,.csv,.ods" name="file_id" style={{ padding: "10px 0 0 0", border: "none" }} />
                    </FieldWrapper>
                    <InputWrapper name="notes" defaultValue={bulkAddressData.notes} errors={errors} textarea allowClear />
                    <FieldWrapper name="additional_cost" errors={errors}>
                        <Currency
                            key="additional_cost"
                            name="additional_cost"
                            currency={ERP_CURRENCY}
                            defaultValue={bulkAddressData.additional_cost ?? null}
                            controls={false}
                        />
                    </FieldWrapper>
                    <FieldWrapper name="margin" errors={errors}>
                        <InputNumber
                            key="margin"
                            name="margin"
                            defaultValue={bulkAddressData.margin}
                            className='inputfield--percentage'
                            controls={false}
                            suffix="%"
                            step="0.01"
                            precision="2"
                            min={0}
                            onFocus={event => event.target.select()}
                            max={'10000000000'}
                        />
                    </FieldWrapper>
                    <FieldWrapper name="sales_price" errors={errors}>
                        <Currency key="sales_price" name="sales_price" currency={ERP_CURRENCY} value={bulkAddressData.sales_price} disabled controls={false} />
                    </FieldWrapper>
                    <div className="">
                    {!!bulkAddressData.bulk_address_file?.url && (
							<Space>
								<Button
									icon={<CloudDownloadOutlined />}
									href={getFilePath(bulkAddressData.bulk_address_file.url, projectId)}
								/>
								<Confirm
									content="Are you sure to delete this line?"
									okText="Yes"
									icon={<DeleteOutlined />}
									shape="square"
									type="actionpanel"
									onConfirm={() => {
										deleteFetcher.submit(
											{
												action: "removeBulkDeliveryFile",
												file_id: bulkAddressData.id,
											},
											{
												method: "DELETE",
												encType: "application/json",
											}
										);
									}}
									danger
									ghost
								/>
							</Space>
						)}
                    </div>
                </div>
                <p style={{ marginTop: "5px" }}>Supported formats : XLS, XLSX, CSV, ODS </p>
            </SmallSection>
            <div className="actions">
                <Button
                    type="primary"
                    htmlType='submit'
                    name="action" value='saveBulkDeliveryAddress'
                    loading={fetcher.state !== 'idle'}
                >Submit</Button>
            </div>
        </fetcher.Form>
    )
}
BulkDeliveryAddresses.propTypes = {
    projectId: PropTypes.number.isRequired,
    bulkAddressData: PropTypes.object.isRequired
}

const DeliveryAddresses = ({ itemDetails, projectId, customerId, disabled }) => {
    const [deliveryType, setDeliveryType] = useState(itemDetails.delivery_type === 3 ? 'bulk' : 'normal')
    const orderQuantity = useMemo(() => itemDetails.approved_quantity ? itemDetails.approved_quantity : itemDetails.quantities?.length === 1 ? itemDetails.quantities[0] : null, [itemDetails])

    return (
        <>
            <Box header={
                <GeneralFieldWrapper label="Delivery Type" horizontal>
                    <Segmented
                        options={[{
                            value: 'normal',
                            label: 'Single/Multiple'
                        }, {
                            value: 'bulk',
                            label: 'Bulk Delivery Addresses'
                        }]}
                        value={deliveryType}
                        onChange={(value) => {
                            setDeliveryType(value)
                        }}
                        disabled={disabled}
                    />
                </GeneralFieldWrapper>
            }>
                {deliveryType === 'bulk' ? (
                    <BulkDeliveryAddresses bulkAddressData={itemDetails.item_delivery_addresses?.[0] ?? {}} projectId={projectId} disabled={disabled} />
                ) : (
                    <NormalDeliveryAddresses
                        orderQuantity={orderQuantity}
                        deliveryAddresses={itemDetails.item_delivery_addresses}
                        customerId={customerId}
                        disabled={disabled}
                    />
                )}
            </Box>
        </>
    )
}
DeliveryAddresses.propTypes = {
    itemDetails: PropTypes.object.isRequired,
    projectId: PropTypes.number.isRequired,
    customerId: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
}

export { DeliveryAddresses };
