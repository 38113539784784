import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { searchUsers, searchUsersByRole } from '../../api/customer/account-manager.js';
import { UserRoles } from '../../library/constants/userRoles.js';

function OrderManagerMultiSelect({ value = [], onChange, currentSelections = [{}], ...otherProps }) {
    const debounceTimeout = 800
    const [fetching, setFetching] = useState(false);
    const selectedOptions = currentSelections?.filter(cs => value?.includes(cs.id)).map(({ id, name }) => ({ label: name, value: id }))
    const [options, setOptions] = useState(selectedOptions);
    const [searchValue, setSearchValue] = useState('')
    const fetchRef = useRef(0);

    useEffect(() => {
        debounceFetcher()
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            searchUsersByRole(value, [UserRoles.ORDER_MANAGER]).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                // if (!selectedOption.some(({ value: v }) => v === currentSelection.value)) {
                // setOptions([...selectedOption, ...newOptions]);
                // }
                // else {
                setOptions([...newOptions]);
                // }
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [searchUsers, debounceTimeout]);

    return (
        <Select
            filterOption={false}
            searchValue={searchValue}
            onSearch={(q) => {
                setSearchValue(q)
                debounceFetcher(q)
            }}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            onChange={onChange}
            value={value || []}
            placeholder="Select Order Managers"
            mode="multiple"
            loading={fetching}
            showSearch
            allowClear
            autoClearSearchValue={true}
            {...otherProps}
        />
    );
}

OrderManagerMultiSelect.propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
    currentSelections: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    }),
}

export default OrderManagerMultiSelect;