import { CheckCircleOutlined, CheckOutlined, ClockCircleOutlined, CloseOutlined, DownloadOutlined, FileTextOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Statistic, Tag, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Await, Link, Outlet, redirect, useActionData, useFetcher, useLoaderData, useLocation, useNavigate, useParams, useSubmit } from 'react-router-dom';
import { getOrderDetails, getOrderDocumentsNew } from '../../../api/order';
import { getProductDetails } from '../../../api/product';
import { getProjectDetails } from '../../../api/project';
import { cancelAddtionalQuotation, getAdditonalQuoataion, getProjectItem, postAddtionalQuotation, resendAddtionalQuotation } from '../../../api/project-item';
import { ItemDetailsSection } from '../../../components/data';
import { OrderDocumentsNew } from '../../../components/data/OrdeManager/order-documents';
import { Box, SmallSection } from '../../../components/design';
import { AdditionalQuoteForm } from '../../../components/form';
import Confirm from '../../../components/message/Confirm.jsx';
import { Page } from '../../../components/page';
import { ValidationError } from '../../../library/classes/exceptions';
import { ProjectStatuses, defaultProductMargin, generateRouteUrl, getFilePath, getProjectStatusByID } from '../../../library/constants';
import { AdditionalQuotationStatus, AdditionalQuotationStatusText } from '../../../library/constants/ItemClientStatuses';
import { AdditionalQuotationStatuses } from '../../../library/constants/additional-quotation-statuses.js';
import { ERP_CURRENCY } from '../../../library/constants/dynamic.js';
import { getFormattedId } from '../../../library/helpers';
import { formatDateTime, formatPrice } from '../../../library/utilities/intl';
import styles from './additional-quote.module.scss';
import { SaleItemAlertTypes } from './item-alerts.jsx';

const ExistingAdditionalQuotation = ({ data, projectId, projectCurrency, itemId }) => {
    const fetcher = useFetcher()
    const navigate = useNavigate()

    const handleCancelQuotation = (quotationId) => {
        fetcher.submit({
            quotationId,
            action: 'cancelAdditonalQuotation'
        }, {
            method: 'post',
            encType: 'application/json'
        })
    }
    const handleReSendAdditionalQuotation = (quotationId) => {
        fetcher.submit({
            quotationId,
            action: 'resendAdditonalQuotation'
        }, {
            method: 'post',
            encType: 'application/json'
        })
    }

    return (
        <Box
            key={data.id}
            type={Box.BoxTypes.GRAY}
            header={
                <div style={{ display: 'flex' }}>
                    <Tag>Reference No. : {data.id}</Tag>
                    <Tag
                        color={
                            data.status === AdditionalQuotationStatuses.APPROVED ?
                                'success' :
                                'processing'
                        }
                        icon={
                            data.status === AdditionalQuotationStatuses.APPROVED ?
                                <CheckCircleOutlined /> :
                                <ClockCircleOutlined />
                        }>Status: {AdditionalQuotationStatus[data?.status]}</Tag>
                </div>
            }
            footer={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        Sent at <strong>{formatDateTime(data.created_at)} </strong>
                        by <strong>{data.created_by_user.name} </strong>
                        {!!data.approved_at && (
                            <>
                                and approved at <strong>{formatDateTime(data.approved_at)} </strong>
                                by <strong>{data.approved_by_user?.name}</strong>
                            </>
                        )}
                    </div>
                    <div className='actions'>
                        {data?.status == AdditionalQuotationStatusText.New || data?.status == AdditionalQuotationStatusText.Sent &&
                            <Confirm
                                content='The quotation is already sent to client. Do you really want to cancel it ?'
                                onConfirm={() => {
                                    handleCancelQuotation(data?.id)
                                }}
                                icon={<CloseOutlined />}
                                type="danger"
                                size="small"
                            >Cancel Quotation</Confirm>
                        }
                        {data.status == AdditionalQuotationStatusText.Sent && <Button type="primary" onClick={() => {
                            handleReSendAdditionalQuotation(data?.id)
                        }} size="small">Re-Send</Button>}
                        <Button type="primary" onClick={() => {
                            navigate(generateRouteUrl('AdditionalQuotationPreview', {
                                projectId,
                                itemId,
                                id: data?.id
                            }))
                        }} size="small">View</Button>
                    </div>
                </div>
            }
        >
            <div className={styles.formGrid}>
                <div>Reason</div>
                <div>Description</div>
                <div>Purchase Price</div>
                <div>Margin</div>
                <div>Selling Price</div>
                <div></div>
            </div>
            {data.item_additional_quotation_lines.map((line) => {
                return (
                    <SmallSection key={line.id}>
                        <div className={styles.gridValues}>
                            <div>{line.name}</div>
                            <div>{line.description}</div>
                            <div>{formatPrice(line.price, ERP_CURRENCY)}</div>
                            <div>{line.margin}%</div>
                            <div>
                                {formatPrice(line.sales_price, ERP_CURRENCY)}
                                {projectCurrency !== ERP_CURRENCY && (
                                    <p>{formatPrice(line.sales_converted?.[projectCurrency], projectCurrency)}</p>
                                )}
                            </div>
                            <div></div>
                        </div>
                    </SmallSection>
                )
            })}
        </Box >
    )
}
ExistingAdditionalQuotation.propTypes = {
    data: PropTypes.object.isRequired,
    itemId: PropTypes.number.isRequired,
    projectId: PropTypes.number.isRequired,
    projectCurrency: PropTypes.oneOf(['USD', 'EUR', 'GBP']).isRequired,
}

const defaultQuotationLine = { name: '', reason: undefined, price: null, margin: defaultProductMargin, description: '', sales_price: null, item_notification_id: null }

const AdditionalQuotation = ({ title }) => {
    const { additionalQuotation, projectDetails, itemDetails, productDetails, orderDetails, orderDocuments } = useLoaderData()
    // const [form] = Form.useForm()
    const submit = useSubmit()
    const actionData = useActionData()
    const navigate = useNavigate()
    const { projectId } = useParams()
    const formResetCounterRef = useRef(0)
    const [inputFields, setInputFields] = useState([
        defaultQuotationLine
    ])
    const location = useLocation()

    useEffect(() => {
        //Reset form is url has submitted param
        if (location.search && (new URLSearchParams(location.search))?.get('submitted')) {
            setInputFields([
                defaultQuotationLine
            ])
            formResetCounterRef.current++
        }
    }, [location.search])

    useEffect(() => {
        //Automatically create lines if coming from alerts
        if (location.search && (new URLSearchParams(location.search))?.get('fromAlerts')) {
            setInputFields(itemDetails.active_alert?.map(alert => ({
                ...defaultQuotationLine,
                item_notification_id: alert.id,
                //Todo: Add better login for mapping alert type to reason
                reason: alert.notification_type === SaleItemAlertTypes.DELIVERY ? 1 : alert.notification_type === SaleItemAlertTypes.SPECS ? 3 : alert.notification_type === SaleItemAlertTypes.STUDIO ? 4 : 5,
                description: alert.title,
            })))
            formResetCounterRef.current++
        }
    }, [])

    const handleFormChange = (index, quotationLine) => {
        let data = [...inputFields];
        data[index] = quotationLine;
        setInputFields(data);
    }

    const handleAddMore = () => {
        setInputFields([...inputFields, defaultQuotationLine])
    }


    const previewQuotationHandler = async (e) => {
        e.preventDefault();
        submit({
            additional_quotation: [...inputFields],
            action: 'previewQuotation'
        }, {
            method: 'post',
            encType: 'application/json',
            action: generateRouteUrl('AdditionalQuotation', {
                projectId,
                itemId: itemDetails.id
            }),
            replace: true,
        })
    }
    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    return (
        <Page className='projects-create' title={title} backLink={generateRouteUrl('ProjectUpdate', { projectId })} header={
            <div className='headerStats'>
                <Statistic
                    title="Project ID"
                    value={getFormattedId(projectId)}
                    formatter={v => v}
                    valueStyle={{
                        color: '#5A5A5A',
                        backgroundColor: '#E6E6E6'
                    }}
                />
                <Statistic
                    title="Project Status"
                    value={projectDetails.status.name}
                />
                {ProjectStatuses.CONFIRMATION_CLIENT_SENT === projectDetails.status.id
                    &&
                    <div className={styles.confirmLetter}>
                        <p className={styles.smallFont}>Client Confirmation Letter</p>
                        <Link target='blank' to={getFilePath(projectDetails?.client_quotation?.pdf?.approved_quotation, projectId)}>
                            <Button icon={<DownloadOutlined />} type='primary'>Download PDF</Button></Link>
                    </div>
                }
            </div>}>
            <Box>
                <ItemDetailsSection readOnly={false} orderDetails={orderDetails} productDetails={productDetails} itemDetails={itemDetails} />
            </Box>
            <Box
                icon={<InboxOutlined style={{ fontSize: '20px', color: 'red' }} />}
                header={
                    <div className={styles.headingText}>
                        Create Additional Quotation
                    </div>
                }
            >
                <Box type={Box.BoxTypes.GRAY} header={
                    <div className={styles.formGrid}>
                        <h4>Reason</h4>
                        <h4>Description</h4>
                        <h4>Purchase Price</h4>
                        <h4>Margin</h4>
                        <h4>Selling Price</h4>
                        <h4></h4>
                    </div>

                }>
                    {inputFields.map((input, index) => {
                        return (
                            <AdditionalQuoteForm
                                key={`${formResetCounterRef.current}-${index}`}
                                errors={actionData?.errors?.[index]}
                                projectCurrencyCode={projectDetails.currency?.code}
                                conversion_rate={itemDetails.conversion_rate}
                                index={index}
                                initialValues={input}
                                onFormChange={(values) => handleFormChange(index, values)}
                                onDelete={() => {
                                    removeFields(index)
                                }}
                            />
                        )
                    })}
                    <div className="actions align--right">
                        <Button type="primary" ghost icon={<PlusOutlined />} onClick={handleAddMore}>Add Line</Button>
                    </div>
                </Box>
                <div className='actions'>
                    <Button onClick={() => {
                        navigate(-1)
                    }} icon={<CloseOutlined />} type="danger" ghost>Cancel</Button>
                    <Button
                        onClick={previewQuotationHandler}
                        icon={<CheckOutlined />}
                        type="primary"
                        // loading={(fetcher.state === 'submitting' || fetcher.state === 'loading') && fetcher.json?.action === 'previewQuotation'}
                        ghost
                    >Preview & Send</Button>
                </div>
            </Box>
            <Box header={<h3>Existing Quotations for this Item</h3>}>
                {additionalQuotation && additionalQuotation.map((data) => {
                    return <ExistingAdditionalQuotation
                        key={data.id}
                        data={data}
                        projectCurrency={projectDetails?.currency?.code}
                        itemId={itemDetails.id}
                        projectId={projectDetails.id}
                    />
                })}
            </Box>
            <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                <Collapse.Panel header={<><FileTextOutlined /> Documents</>} key="1">
                    <React.Suspense fallback={<p>Loading Documents...</p>}>
                        <Await
                            resolve={orderDocuments}
                            errorElement={
                                <p>Error loading documents!</p>
                            }
                        >
                            {(orderDocuments) => <OrderDocumentsNew projectId={orderDetails.project_id} documents={orderDocuments} />}
                        </Await>
                    </React.Suspense>
                </Collapse.Panel>
            </Collapse>
            <Outlet />
        </Page>
    )
}

AdditionalQuotation.propTypes = {
    title: PropTypes.string
}

AdditionalQuotation.Loader = async ({ params }) => {
    if (isNaN(params.itemId)) {
        throw new Error('Invalid Item ID')
    }
    const additionalQuotation = await getAdditonalQuoataion(params.itemId)
    const projectDetails = await getProjectDetails(params.projectId)
    const itemDetails = await getProjectItem(params.itemId)
    itemDetails.selected_vendor = itemDetails.selected_vendors.find(({ id }) => id === itemDetails.vendor_selected_id)
    itemDetails.selected_vendor = null
    delete itemDetails.selected_vendors

    itemDetails.vendors_prices = itemDetails.vendors_prices[itemDetails.vendor_selected_id]
    const orderDetails = await getOrderDetails(params.itemId)
    if (!orderDetails) {
        throw new Error('Invalid Order')
    }
    projectDetails.status = getProjectStatusByID(projectDetails.status == null ? 1 : projectDetails.status)
    if (!projectDetails) {
        throw new Error('Invalid Project')
    }
    const productDetails = await getProductDetails(orderDetails.product.id, itemDetails.project.language.cms_language_code)
    if (!productDetails) {
        throw new Error('Invalid Product')
    }
    const orderDocuments = getOrderDocumentsNew(params.itemId)
    return { additionalQuotation, projectDetails, itemDetails, productDetails, orderDetails, orderDocuments }


}


AdditionalQuotation.Actions = {
    resendAdditonalQuotation: async ({ data }) => {
        const { quotationId } = data
        try {
            const { message: msg } = await resendAddtionalQuotation(quotationId)
            message.success(msg)
            return { close: true }
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errros
                }
            }
        }
        return false
    },
    cancelAdditonalQuotation: async ({ data }) => {
        const { quotationId } = data
        try {
            const { message: msg } = await cancelAddtionalQuotation(quotationId)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errros
                }
            }
        }
        return false
    },
    previewQuotation: async ({ params, data }) => {
        const { itemId, projectId } = params
        const { additional_quotation } = data
        try {
            const quotationId = await postAddtionalQuotation(itemId, additional_quotation)
            if (!quotationId) {
                throw new Error('Unable to preview quotation')
            }
            return redirect(generateRouteUrl('AdditionalQuotationPreview', {
                id: quotationId,
                projectId,
                itemId
            }))
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false

    },
}

export default AdditionalQuotation