import { Input, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidationForm as Form } from '../';
import styles from './custom-specification.module.scss';

const CustomSpecificationForm = ({ form, children, ...otherProps }) => {
    const validationRules = {
        name_default: [{
            required: true,
            message: 'Specification name is required'
        }],
        name: [{
            required: true,
             message: 'Specification name tranlation is required'        
        }],
        value_default: [{
            required: true,
            message: 'Specification value is required'
        }],
        value: [{
            required: true,
            message: 'Specification value translation is required'
        }]
    }

    return (
        <Form name='add-custom-specification' layout="vertical" form={form} className={styles.Form} validationRules={validationRules} {...otherProps} preserve={false}>
            <Tooltip title='The type of specification eg. color, size, etc'>
                <Form.Item name="name_default" label="Specification">
                    <Input />
                </Form.Item>
            </Tooltip>
            <Tooltip title='The value of the specification eg. red, A4, etc' >
                <Form.Item name="value_default" label="Specification Value">
                    <Input />
                </Form.Item>
            </Tooltip>
            <Tooltip title='The translation of the spcification type, eg . Farbe, Größe etc' >
                <Form.Item name="name" label="Specification Name Translation">
                    <Input />
                </Form.Item>
            </Tooltip>
            <Tooltip title='The translation of the spcification value, eg . rot, A4 etc'>
                <Form.Item name="value" label="Specification Value Translation">
                    <Input />
                </Form.Item>
            </Tooltip>
            {children}
        </Form>
    )
}

CustomSpecificationForm.propTypes = {
    form: PropTypes.object.isRequired,
    children: PropTypes.node,
}

export default CustomSpecificationForm