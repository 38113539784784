import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import {
    ArtWorkRejected,
    ArtworkReady, ArtworkReceived,
    ArtworkSentToStudio,
    CancelRequest,
    DelayInDelivery, Delivered, DispatchDateConfirm, InProduction,
    OnHold,
    OrderConfirmation,
    OrderSent,
    ProductDispatch,
    ProofReady,
    ProofRecieved, ProofRejected, ProofRejectedbyCustomer,
    ProofRequested,
    ProofSentToStudio,
    ProofSenttoCustomer, ReadyForProduction, RequestTemplatefromVendor, SendTemplate,
    TemplateReady,
    TemplateRecievedFromVendor,
    TemplateSenttoStudio,
    WaitingForFile
} from "../../../routes/orderManager";
import ArtworkSentToCustomer from '../../../routes/orderManager/tasks/artwork-sent-to-customer.jsx';
import MakeArtworkRejected from '../../../routes/orderManager/tasks/make-artowrk-rejected.jsx';
import MakeArtworkReady from '../../../routes/orderManager/tasks/make-artwork-ready.jsx';
import MakeArtworkSenttoStudio from '../../../routes/orderManager/tasks/make-artwork-sent-to-studio.jsx';

export const OrderManagerSteps = [
    {
        id: 'OrderConfirmation',
        status: 1,
        icon: <img src="/icons/timeline/1orderconfirmation.svg" style={{ width: '1rem' }} />,
        title: 'Order Confirmation',
        component: () => OrderConfirmation,
    },
    {
        id: 'OrderSent',
        status: 2,
        icon: <img src="/icons/timeline/2ordersent.svg" style={{ width: '1rem' }} />,
        activeIcon: <LoadingOutlined />,
        title: 'Order Sent',
        component: () => OrderSent,
    },
    {
        id: 'WaitingForFile',
        status: 3,
        icon: <img src="/icons/timeline/3waitingforfiles.svg" style={{ width: '1rem' }} />,
        title: 'Waiting for Files',
        component: () => WaitingForFile,
    },
    {
        id: 'RequestTemplatefromVendor',
        status: 4,
        icon: <img src="/icons/timeline/4requesttemplatefromvendor.svg" style={{ width: '1rem' }} />,
        title: 'Request Template from Vendor',
        component: () => RequestTemplatefromVendor,
    },
    {
        id: 'TemplateRecievedFromVendor',
        status: 5,
        icon: <img src="/icons/timeline/5TemplateRecievedFromVendor.svg" style={{ width: '1rem' }} />,
        title: 'Template Recieved from Vendor',
        component: () => TemplateRecievedFromVendor,
    },
    {
        id: 'TemplateSenttoStudio',
        status: 6,
        icon: <img src="/icons/timeline/6templatesenttostudio.svg" style={{ width: '1rem' }} />,
        title: 'Template Sent to Studio',
        component: () => TemplateSenttoStudio,
    },
    {
        id: 'TemplateReady',
        status: 7,
        icon: <img src="/icons/timeline/7templateready.svg" style={{ width: '1rem' }} />,
        title: 'Template Ready',
        component: () => TemplateReady,
    },
    {
        id: 'SendTemplate',
        status: 8,
        icon: <img src="/icons/timeline/8sendtemplate.svg" style={{ width: '1rem' }} />,
        title: 'Template Sent',
        component: () => SendTemplate,
    },
    {
        id: 'MakeArtworkSenttoStudio',
        status: 101,
        icon: <img src="/icons/timeline/101makeartworksenttostudio.svg" style={{ width: '1rem' }} />,
        title: 'Make Artwork Sent to Studio',
        component: () => MakeArtworkSenttoStudio,
    },
    {
        id: 'MakeArtworkReady',
        status: 102,
        icon: <img src="/icons/timeline/102makeartworkready.svg" style={{ width: '1rem' }} />,
        title: 'Make Artwork Ready',
        component: () => MakeArtworkReady,
    },
    {
        id: 'ArtworkSentToCustomer',
        status: 9,
        icon: <img src="/icons/timeline/9artworksenttocustomer.svg" style={{ width: '1rem' }} />,
        title: 'Artwork Sent To Customer',
        component: () => ArtworkSentToCustomer,
    },
    {
        id: 'MakeArtworkRejected',
        status: 10,
        icon: <img src="/icons/timeline/10makeartworkrejected.svg" style={{ width: '1rem' }} />,
        title: 'Make Artwork Rejected',
        component: () => MakeArtworkRejected,
    },
    {
        id: 'ArtworkReceived',
        status: 11,
        icon: <img src="/icons/timeline/11artworkreceived.svg" style={{ width: '1rem' }} />,
        title: 'ArtWork Received',
        component: () => ArtworkReceived,
    },
    {
        id: 'ArtWorkRejected',
        status: 12,
        icon: <img src="/icons/timeline/12artworkrejected.svg" style={{ width: '1rem' }} />,
        title: 'ArtWork Rejected',
        component: () => ArtWorkRejected,
    },
    {
        id: 'ArtworkSentToStudio',
        status: 13,
        icon: <img src="/icons/timeline/13artworksenttostudio.svg" style={{ width: '1rem' }} />,
        title: 'ArtWork Sent to Studio',
        component: () => ArtworkSentToStudio,
    },
    {
        id: 'ArtworkReady',
        status: 14,
        icon: <img src="/icons/timeline/14artworkready.svg" style={{ width: '1rem' }} />,
        title: 'ArtWork Ready',
        component: () => ArtworkReady,
    },
    {
        id: 'ProofRequested',
        status: 15,
        icon: <img src="/icons/timeline/15proofrequested.svg" style={{ width: '1rem' }} />,
        title: 'Proof Requested',
        component: () => ProofRequested,
    },
    {
        id: 'ProofRecieved',
        status: 16,
        icon: <img src="/icons/timeline/16proofreceived.svg" style={{ width: '1rem' }} />,
        title: 'Proof Received',
        component: () => ProofRecieved,
    },
    {
        id: 'ProofRejected',
        status: 17,
        icon: <img src="/icons/timeline/17proofrejected.svg" style={{ width: '1rem' }} />,
        title: 'Proof Rejected',
        component: () => ProofRejected,
    },
    {
        id: 'ProofSentToStudio',
        status: 18,
        icon: <img src="/icons/timeline/18proofsenttostudio.svg" style={{ width: '1rem' }} />,
        title: 'Proof Request Sent to Studio',
        component: () => ProofSentToStudio,
    },
    {
        id: 'ProofReady',
        status: 19,
        icon: <img src="/icons/timeline/19proofready.svg" style={{ width: '1rem' }} />,
        title: 'Proof Ready',
        component: () => ProofReady,
    },
    {
        id: 'ProofSenttoCustomer',
        status: 20,
        icon: <img src="/icons/timeline/20proofsenttocustomer.svg" style={{ width: '1rem' }} />,
        title: 'Proof Sent to Client',
        component: () => ProofSenttoCustomer,
    },
    {
        id: 'ProofRejectedbyCustomer',
        status: 21,
        icon: <img src="/icons/timeline/21proofrejectedbycustomer.svg" style={{ width: '1rem' }} />,
        title: 'Proof Rejected by Client',
        component: () => ProofRejectedbyCustomer,
    }, {
        id: 'ReadyForProduction',
        status: 22,
        icon: <img src="/icons/timeline/22readyforproduction.svg" style={{ width: '1rem' }} />,
        title: 'Ready for Production',
        component: () => ReadyForProduction,
    }, {
        id: 'InProduction',
        status: 23,
        icon: <img src="/icons/timeline/23inproduction.svg" style={{ width: '1rem' }} />,
        title: 'In Production',
        component: () => InProduction,
    },
    {
        id: 'DispatchDateConfirm',
        status: 24,
        icon: <img src="/icons/timeline/24dispatchdateconfirm.svg" style={{ width: '1rem' }} />,
        title: 'Expected Dispatch Date',
        component: () => DispatchDateConfirm,
    },
    {
        id: 'OnHold',
        status: 28,
        icon: <img src="/icons/timeline/28onhold.svg" style={{ width: '1rem' }} />,
        title: 'On Hold',
        component: () => OnHold,
    },
    {
        id: 'ProductDispatch',
        status: 25,
        icon: <img src="/icons/timeline/25productdispatch.svg" style={{ width: '1rem' }} />,
        title: 'Product Dispatched',
        component: () => ProductDispatch,
    },
    {
        id: 'DelayInDelivery',
        status: 27,
        icon: <img src="/icons/timeline/27delayindelivery.svg" style={{ width: '1rem' }} />,
        title: 'Delay in Delivery',
        component: () => DelayInDelivery,
    },
    {
        id: 'CancelRequest',
        status: 29,
        icon: <img src="/icons/timeline/29cancelrequest.svg" style={{ width: '1rem' }} />,
        title: 'Cancel Request',
        component: () => CancelRequest,
    },
    {
        id: 'Delivered',
        status: 26,
        icon: <img src="/icons/timeline/26delivered.svg" style={{ width: '1rem' }} />,
        title: 'Delivered',
        component: () => Delivered,
    },
]

export const getOrderManagerSteps = () => OrderManagerSteps.map(({ icon, activeIcon = null, status, title }) => ({
    icon,
    status,
    title,
    activeIcon
}))

export const getOrderStatusName = (status) => OrderManagerSteps.find(step => step.status === status)?.title