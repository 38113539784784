import { LoadingOutlined } from '@ant-design/icons'
import { throttle } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './kanaban.module.scss'
import './kanaban.scss'

function getWidthExcludingPadding(element) {
    if (!element) return 0;

    // Get the computed styles of the element
    const style = window.getComputedStyle(element);

    // Extract left and right padding values
    const paddingLeft = parseFloat(style.paddingLeft) || 0;
    const paddingRight = parseFloat(style.paddingRight) || 0;

    // Calculate the scrollWidth excluding padding
    const scrollWidthExcludingPadding = element.clientWidth - paddingLeft - paddingRight;

    return scrollWidthExcludingPadding;
}

const KanabanBoard = ({ columns, isLoading, renderItem, onItemClick, hasMore, loadMore }) => {
    const endElementRef = useRef([])
    const kanabanRef = useRef(null)
    const location = useLocation()
    const observer = useRef(null)
    const scrollMapRef = useRef(null)
    const scrollIndicatorRef = useRef(null)
    const isDragging = useRef(false)
    const startDragX = useRef(0)

    const debouncedLoadMore = useRef(
        throttle(() => {
            if (hasMore) loadMore()
        }, 200)
    ).current

    useEffect(() => {
        return () => {
            debouncedLoadMore.current?.cancel()
        };
    }, []);

    const updateScrollIndicator = () => {
        const kanaban = kanabanRef.current
        const scrollMap = scrollMapRef.current?.parentElement
        const scrollIndicator = scrollIndicatorRef.current

        if (!kanaban || !scrollMap || !scrollIndicator) return
        const mapRatio = kanaban.clientWidth / kanaban.scrollWidth
        const scrollMapInnerWidth = getWidthExcludingPadding(scrollMap) + 4
        const indicatorWidth = Math.max(20, mapRatio * scrollMapInnerWidth) // Minimum width of 20px
        if (indicatorWidth === scrollMapInnerWidth) {
            scrollMap.style.visibility = 'hidden'
            return
        }
        scrollMap.style.visibility = 'visible'
        scrollIndicator.style.width = `${indicatorWidth}px`

        const leftRatio = kanaban.scrollLeft / (kanaban.scrollWidth - kanaban.clientWidth)
        const indicatorLeft = leftRatio * (scrollMapInnerWidth - indicatorWidth)
        scrollIndicator.style.left = `${indicatorLeft}px`
    }

    const handleScroll = () => {
        updateScrollIndicator()
    }

    useEffect(() => {
        endElementRef.current = endElementRef.current.slice(0, columns.length)
        updateScrollIndicator()
    }, [columns])

    useEffect(() => {
        if (observer.current) observer.current.disconnect()
        if (!endElementRef.current.length || !hasMore) return

        observer.current = new IntersectionObserver((entries, obs) => {
            const anyIntersecting = entries.some(entry => entry.isIntersecting)
            if (anyIntersecting) {
                debouncedLoadMore()
                if (!hasMore) {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) obs.unobserve(entry.target)
                    })
                }
            }
        }, {
            root: document.querySelector('.kanaban-wrapper'),
            rootMargin: '0px',
            threshold: [0, 0.5, 1],
        })

        endElementRef.current.forEach(el => el && observer.current.observe(el))

        return () => {
            if (observer.current) {
                observer.current.disconnect()
            }
        }
    }, [hasMore, loadMore, endElementRef.current])

    useEffect(() => {
        kanabanRef.current?.scrollTo(0, 0)
    }, [location])

    const handleDragStart = (e) => {
        e.preventDefault();
        isDragging.current = true
        startDragX.current = e.clientX
        document.addEventListener('mousemove', handleDrag)
        document.addEventListener('mouseup', handleDragEnd)
        if (scrollIndicatorRef.current) {
            scrollIndicatorRef.current.style.cursor = 'grabbing'
        }
    }

    const handleDrag = (e) => {
        if (!kanabanRef.current || !scrollMapRef.current) return

        const deltaX = (e.clientX - startDragX.current) * 1.20 //Increase speed by 20%
        const scrollMap = scrollMapRef.current
        const kanaban = kanabanRef.current

        const { scrollWidth, clientWidth, scrollLeft } = kanaban
        const scrollMapWidth = scrollMap.offsetWidth

        const mapRatio = (scrollWidth - clientWidth) / scrollMapWidth

        const newScrollLeft = scrollLeft + deltaX * mapRatio

        const clampedScrollLeft = Math.max(
            0,
            Math.min(newScrollLeft, scrollWidth - clientWidth)
        )

        kanaban.scrollLeft = clampedScrollLeft

        startDragX.current = e.clientX

        updateScrollIndicator()
    }

    const handleDragEnd = () => {
        isDragging.current = false
        document.removeEventListener('mousemove', handleDrag)
        document.removeEventListener('mouseup', handleDragEnd)
        if (scrollIndicatorRef.current) {
            scrollIndicatorRef.current.style.cursor = 'grab'
        }
    }

    useEffect(() => {
        const kanaban = kanabanRef.current
        if (kanaban) {
            kanaban.addEventListener('scroll', handleScroll)
            updateScrollIndicator()
        }

        return () => {
            if (kanaban) kanaban.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const itemRender = useCallback((data) => {
        try {
            return renderItem(data)
        } catch (error) {
            console.error(error, data)
            return <div className="">Error Rendering Item</div>
        }
    }, [])

    return (
        <div className="kanaban-wrapper">
            <div className={styles.kanabanWrapper} ref={kanabanRef}>
                <div className={styles.kanaban}>
                    {columns.filter(({ count }) => count > 0).map(({ key, title, items, count }, index) => (
                        <div
                            key={key}
                            className={(count === 0 || (items.length === 0 && !hasMore)) ? styles.kanabanColumnVanish : styles.kanabanColumn}
                        >
                            <div className={styles.kanabanColumnHeader} title={title}>
                                <span className={styles.kanabanColumnTitle}>{title}</span>
                                <span className={styles.kanabanColumnCount}>
                                    ({count ?? items.length})
                                </span>
                            </div>
                            {items.map(item => (
                                <React.Fragment key={item.id}>
                                    {itemRender({ item, onClick: onItemClick })}
                                </React.Fragment>
                            ))}
                            <div
                                ref={el => (endElementRef.current[index] = el)}
                                className={styles.scrollToEnd}
                            >
                                {isLoading ? <LoadingOutlined /> : hasMore ? <>Load More</> : <>No more items</>}
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    className={styles.scrollMap}

                >
                    <div className={styles.scrollBackground} ref={scrollMapRef}>
                        {columns?.filter(c => c.items.length > 0)?.map((column) => (
                            <div
                                key={column.key}
                                className={styles.scrollMapColumn}
                            />
                        ))}
                    </div>
                    <div
                        ref={scrollIndicatorRef}
                        className={styles.scrollIndicator}
                        onMouseDown={handleDragStart}
                    />
                </div>
            </div>
        </div>
    )
}

KanabanBoard.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    renderItem: PropTypes.func,
    onItemClick: PropTypes.func,
    isLoading: PropTypes.bool,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func,
}

export default KanabanBoard
