import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, Drawer, Modal, Rate, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from "react";
import { useFetcher, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { z } from "zod";
import { addItemVendorRating, clientDeliveryComplete, getItemVendorRating } from '../../../api/order';
import { Box } from '../../../components/design/box.js';
import { DocumentsDownloader } from '../../../components/form/index.js';
import { GeneralFieldWrapper } from '../../../components/inputs/input-wrappers.jsx';
import { ValidationError } from '../../../library/classes/exceptions/index.js';
import { ItemClientStatuses } from '../../../library/constants/ItemClientStatuses.js';
import { formats } from '../../../library/constants/formats.js';
import { getCountryById } from '../../../library/helpers/country.js';
import { formatDate } from '../../../library/utilities/intl.js';
import { getFilePath } from '../../../library/utilities/urls.js';
import styles from "./in-production.module.scss";

const ratingSchema = z.object({
    delivery: z.number({ required_error: 'Delivery rating is required' }).min(1).max(5),
    response: z.number({ required_error: 'Response rating is required' }).min(1).max(5),
    quality: z.number({ required_error: 'Quality rating is required' }).min(1).max(5),
})

const ItemDeliveryDetails = ({ projectId, deliveryType, shipments, deliveryDate }) => {
    const getFormattedAddress = (shipment) => {
        if (deliveryType === 3) {
            return (
                <Button href={getFilePath(shipment.bulk_address_file.url, projectId)} icon={<DownloadOutlined />}>Download</Button>
            )
        }
        return (
            <div className="address">
                {shipment.street} {shipment.street_number}<br />
                {shipment.city}, {shipment.county}, {shipment.zipcode}<br />
                {getCountryById(shipment.country_id)?.name}<br />
            </div>
        )
    }
    return shipments.map(item => (
        <Box key={item.id} type={Box.BoxTypes.BLUE} header={(
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '120px 140px 140px auto', fontWeight: 'bold' }}>
                    <div>Quantity</div>
                    <div>Shipped Quantity</div>
                    <div>Delivery Date</div>
                    <div>Address</div>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '120px 140px 140px auto' }}>
                    <div>{item.quantity}</div>
                    <div>{item.ship_quantity}</div>
                    <div>{formatDate(deliveryDate, formats.frontendDateFormat)}</div>
                    <div>{getFormattedAddress(item)}</div>
                </div>
            </div>
        )}>
            {item.order_delivery_proof?.map(file => <DocumentsDownloader key={file.url} project_id={projectId} image={file.url} name={file.name} />)}
            {item.delivery_proof?.map(file => <DocumentsDownloader key={file.url} project_id={projectId} image={file.url} name={file.name} />)}
            <div style={{marginTop:"10px"}}><strong>Comment</strong> : {item.delivery_proof_comment}</div>
        </Box>
    ))
}
ItemDeliveryDetails.propTypes = {
    projectId: PropTypes.number.isRequired,
    deliveryType: PropTypes.oneOf([1, 2, 3]).isRequired,
    shipments: PropTypes.arrayOf(PropTypes.object).isRequired,
    deliveryDate: PropTypes.string.isRequired
}

const Delivered = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const { vendorItemRating } = useLoaderData()
    const [vendorRatingVisible, setVendorRatingVisible] = useState(false)
    const [deliveryDetailsVisible, setDeliveryDetailsVisible] = useState(false)
    const vendorRated = !!vendorItemRating
    const fetcher = useFetcher()
    const ratings = useRef(vendorItemRating ?? {})
    const deliveredMessage = `Order delivered on time on ${formatDate(orderDetails?.delivery_date)}`

    return <div className={styles.container}>
        <h3>Task for you</h3>
        {orderDetails.client_status !== ItemClientStatuses.DELIVERED ? (
            <Alert message="Order has been delivered" type="info"
                action={
                    <Button
                        className={styles.greenButton}
                        onClick={() => {
                            fetcher.submit({
                                action: 'notifyCustomer'
                            }, {
                                method: 'post'
                            })
                        }}
                        loading={fetcher.state === 'submitting' && fetcher.formData?.get('action') === 'notifyCustomer'}
                    >Notify Customer</Button>
                } />
        ) : (
            <Alert message="Order has been delivered. Customer is also notified." type="info" />
        )}
        <Alert message={deliveredMessage} type="info"
            action={
                <Button className={styles.greenButton} onClick={() => setDeliveryDetailsVisible(true)}>View Delivery Details</Button>
            } />
        <Alert message='How would you rate your experience with vendor ?' type="info" action={
            <Button onClick={() => setVendorRatingVisible(true)}>{!vendorRated ? 'Rate Vendor' : 'View Rating'}</Button>
        } />
        <Drawer
            title="Delivery Details"
            open={deliveryDetailsVisible}
            onClose={() => setDeliveryDetailsVisible(false)}
            size="large"
        >
            <ItemDeliveryDetails projectId={orderDetails.project_id} deliveryType={orderDetails.delivery_type} shipments={orderDetails.item_delivery_address} deliveryDate={orderDetails.delivery_date} />
        </Drawer>
        <Modal
            title="Rate Vendor"
            open={vendorRatingVisible}
            onCancel={() => setVendorRatingVisible(false)}
            cancelText={vendorRated ? 'Close' : false}
            okText={!vendorRated ? 'Submit' : false}
            onOk={() => {
                //Check if ratings are filled
                const parseRatings = ratingSchema.safeParse(ratings.current)
                console.log({ parseRatings, ratings: ratings.current })
                if (!parseRatings.success) {
                    message.error('Please fill in all ratings for vendor')
                    return
                }

                fetcher.submit({
                    ...ratings.current,
                    action: 'vendorRating'
                }, {
                    method: 'post',
                    encType: 'application/json'
                })
            }}
            destroyOnClose
        >
            <div className="form-fields-wrapper--horizontal">
                <GeneralFieldWrapper label="1. Response Time" horizontal>
                    <Rate onChange={v => ratings.current.response = v} defaultValue={ratings.current.response} disabled={vendorRated} />
                </GeneralFieldWrapper>
                <GeneralFieldWrapper label="2. Delivery" horizontal>
                    <Rate onChange={v => ratings.current.delivery = v} defaultValue={ratings.current.delivery} disabled={vendorRated} />
                </GeneralFieldWrapper>
                <GeneralFieldWrapper label="3. Quality of Product" horizontal>
                    <Rate onChange={v => ratings.current.quality = v} defaultValue={ratings.current.quality} disabled={vendorRated} />
                </GeneralFieldWrapper>
            </div>
        </Modal>
    </div>
}

Delivered.Actions = {
    notifyCustomer: async ({ params }) => {
        const { itemId } = params
        try {
            const response = await clientDeliveryComplete(itemId, {})
            const { message: msg } = response
            message.success(msg);
            return true
        }
        catch (error) {
            throw new Response('Unable to Save data', { status: 500 })
        }
    },
    vendorRating: async ({ params, data }) => {
        const { itemId } = params
        try {
            const msg = await addItemVendorRating(itemId, data)
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
            return false
        }
    }
}
Delivered.Loader = async ({ params }) => {
    const { itemId } = params
    let vendorItemRating = null
    try {
        vendorItemRating = await getItemVendorRating(itemId)
    }
    catch {
        //Prevent error when there is no rating
    }
    return { vendorItemRating }
}
export default Delivered;