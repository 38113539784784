import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Select, Switch, Tooltip } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { useActionData } from 'react-router-dom';
import { ValidationForm as Form } from '../';
import { getCustomerDetails } from '../../../api/customer/customer-details.js';
import { PaymentConditions, PaymentTerms } from '../../../library/constants/dynamic.js';
import { ProjectStatuses } from '../../../library/constants/project-statuses.js';
import { AccountManagerSelect, CompanySelect, ExecutiveEntitySelect } from '../../inputs';
import styles from './project-details.module.scss';

const getValidationRules = (source) => {
	const rules = {
		customer_id: [
			{
				required: true,
				message: 'Company is required'
			},
		],
		account_manager_id: [
			{
				required: true,
				message: 'Account Manager is required'
			}
		],
		payment_condition_id: [
			{
				required: true,
				message: 'Payment condition is required'
			}
		],
		payment_term_id: [
			{
				required: true,
				message: 'Payment term is required'
			}
		]
	}

	if (source !== 'website') {
		rules.source_id = [
			{
				required: true,
				message: 'Source is required'
			},
		]
	}
	return rules
}

const ProjectDetails = ({ children, onValuesChange, getValidationStatus, onCustomerChange, initialValues, isEditing, projectStatus, disabled = false }) => {
	const [form] = Form.useForm()
	const { errors = {} } = useActionData() || {};

	const handleValuesChange = debounce(onValuesChange, 1000)

	const handleCompanyChange = (company_id) => {
		if (!company_id) {
			return
		}
		getCustomerDetails(company_id)
			.then((customer) => {
				const { entity_id, payment_term_id } = customer
				form.setFieldsValue({
					entity_id,
					payment_term_id,
				})


				form.validateFields()
					.then(() => {
						handleValidationChange(true)
					})
					.catch(() => {
						handleValidationChange(false)
					})
					.finally(() => {
						if (onCustomerChange)
							onCustomerChange(customer)

						//manually trigger onChange Event of form
						handleValuesChange({
							entity_id,
							payment_term_id
						}, form.getFieldsValue())
					})
			})
	}

	const handleValidationChange = (isValid) => {
		if (getValidationStatus) {
			getValidationStatus(isValid)
		}
	}
	const disabledFields = ['account_manager_id', 'client_order_number', 'payment_term_id', 'payment_condition_id', 'include_vat'];

	const isFieldDisabled = (field) => {
		if (isEditing) {
			if (projectStatus === ProjectStatuses.NEW) {
				return !disabledFields.includes(field);
			}
			if (field === 'source_id' && initialValues[field] === 'website') {
				return true
			}
			// if (field === 'include_vat' && (initialValues[field] === undefined || initialValues[field] === null)) {
			// 	return true
			// }
			if (field !== 'include_vat' && !initialValues?.[field]) {
				return false
			}
			return !['source_id', 'account_manager_id'].includes(field);
		}
	};

	return (
		<Form
			form={form}
			name='projectDetails'
			onValidationStatusChange={handleValidationChange}
			layout="vertical"
			className={styles.FormWrapper}
			validationRules={getValidationRules(initialValues?.source_id)}
			errors={errors}
			onValuesChange={(changedValues, values) => {
				if (!changedValues['customer_id'])
					handleValuesChange(changedValues, values)
			}}
			validateTrigger={['onChange']}
			initialValues={initialValues}
			disabled={disabled}
		>
			<Form.Item name="source_id" label="Source" >
				<Select
					options={initialValues?.source_id === 'website' ? ([
						{
							label: 'Website',
							value: 'website'
						}
					]) : ([
						{
							label: 'Email',
							value: 'email'
						},
						{
							label: 'Phone',
							value: 'phone'
						}
					])}
					disabled={isEditing && projectStatus != ProjectStatuses.NEW}
				>
				</Select>
			</Form.Item>
			<Form.Item name="customer_id" label="Company" >
				<CompanySelect currentSelection={{ value: initialValues.customer_id, label: initialValues?.customer?.company }} onChange={handleCompanyChange} disabled={isFieldDisabled('customer_id')} />
			</Form.Item>
			<Form.Item name="entity_id" label="Entity" >
				<ExecutiveEntitySelect disabled={isFieldDisabled('entity_id')} />
			</Form.Item>
			<Form.Item name="account_manager_id" label="Account Manager" >
				<AccountManagerSelect disabled={isFieldDisabled('account_manager_id')} />
			</Form.Item>
			<Form.Item name="client_order_number" label={<>Client Order Number &nbsp;  <Tooltip title="Client share this no. with sales person. This no. belongs to client And in P.O & Invoices"> <InfoCircleOutlined /> </Tooltip></>} key={initialValues.client_order_number}>
				<Input disabled={isFieldDisabled('client_order_number')} />
			</Form.Item>
			<Form.Item name="payment_condition_id" label="Payment condition" >
				<Select options={PaymentConditions} fieldNames={{ label: 'name', value: 'id' }} disabled={isFieldDisabled('payment_condition_id')} />
			</Form.Item>
			<Form.Item name='payment_term_id' label="Payment Term">
				<Select options={PaymentTerms} fieldNames={{ value: 'id', label: 'name' }} disabled={isFieldDisabled('payment_term_id')} />
			</Form.Item>
			<Form.Item name='include_vat' label="Include VAT" valuePropName="checked">
				<Switch
					disabled={isFieldDisabled('include_vat')}
					checkedChildren="Yes"
					unCheckedChildren="No"
				/>
			</Form.Item>
			{children}
		</Form >
	)
}

ProjectDetails.propTypes = {
	children: PropTypes.node,
	onSubmit: PropTypes.func,
	getValidationStatus: PropTypes.func,
	onValuesChange: PropTypes.func.isRequired,
	initialValues: PropTypes.object,
	onCustomerChange: PropTypes.func,
	isEditing: PropTypes.bool,
	projectStatus: PropTypes.number,
	disabled: PropTypes.bool
	// companyName: PropTypes.string
}

export default ProjectDetails