// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { convertDataTypes } from '../../library/helpers';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/finance/invoice';
const ENDPOINT_GENERAL_ACTION = '/finance/invoice/:action';
const ENDPOINT_SINGLE = '/finance/invoice/:invoiceId';
const ENDPOINT_SINGLE_ACTION = '/finance/invoice/:invoiceId/:action';
const ENDPOINT_INVOICE_LINE = '/finance/invoice/:invoiceId/line';
const ENDPOINT_INVOICE_LINE_SINGLE = '/finance/invoice/:invoiceId/line/:invoiceLineId';
const ENDPOINT_INVOICE_LINE_DELETE = '/finance/invoice/:invoiceLineId/line';

export const getInvoices = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getInvoicesKanabanCount = (payload) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL_ACTION, {
        action: 'kanban-count'
    }), { params: payload }).then(({ data }) => data)
}

export const getInvoiceDetails = (invoiceId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        invoiceId
    })).then(({ data }) => data)
}

export const updateInvoiceDetails = (invoiceId, data) => {
    const payload = convertDataTypes(data, {
        country_id: parseInt,
        entity_id: parseInt,
        footer_text: parseInt,
        gender_id: parseInt,
        invoice_second_contact_id: parseInt
    })
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        invoiceId
    }), payload).then(({ message }) => message)
}

export const addInvoiceLine = (invoiceId, data) => {
    const payload = convertDataTypes(data, {
        quantity: parseInt,
        total_price_excl_vat: parseFloat,
        visible_vat: parseInt,
    })
    return axiosInstance.post(generatePath(ENDPOINT_INVOICE_LINE, {
        invoiceId,
    }), payload).then(({ data }) => data)
}

export const updateInvoiceLine = (invoiceId, invoiceLineId, data) => {
    const payload = convertDataTypes(data, {
        quantity: parseInt,
        total_price_excl_vat: parseFloat,
        visible_vat: parseInt,
    })
    return axiosInstance.patch(generatePath(ENDPOINT_INVOICE_LINE_SINGLE, {
        invoiceId,
        invoiceLineId
    }), payload).then(({ data }) => data)
}

export const deleteInvoiceLine = (invoiceLineId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_INVOICE_LINE_DELETE, {
        invoiceLineId
    })).then(({ message }) => message)
}

export const finalizeInvoice = (invoiceId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'finalize'
    }), {}).then(({ status }) => status)
}

export const invoiceAuditTrail = async (invoiceId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'audit-trial'
    }), {}).then(({ data }) => data)
}

export const createPaymentLink = (invoiceId, payload) => {
    payload = convertDataTypes(payload, {
        amount: parseFloat,
        payment_method: parseInt
    })
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'payment-link'
    }), payload).then(({ message }) => message)
}

export const getPaymentLinks = async (invoiceId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'payment-link'
    })).then(({ data }) => data)
}

export const sendInvoiceToClient = async (invoiceId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'send-to-client'
    }), {}).then(({ message }) => message)
}
export const markInvoiceSent = async (invoiceId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'send-invoice-via-post'
    }), {}).then(({ message }) => message)
}

export const updateInvoicePaymentNotes = async (invoiceId, payment_notes) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'payment-notes'
    }), {
        payment_notes
    }).then(({ message }) => message)
}

export const getInvoiceReminders = async (invoiceId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'reminder'
    })).then(({ data }) => data)
}

export const sendInvoiceReminder = async (invoiceId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'reminder'
    }))
}

export const rescheduleInvoiceReminder = async (invoiceId, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'reminder'
    }), payload)
}

export const setInvoicePaidOnline = async (invoiceId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'marked-online'
    }))
}

export const unsetInvoicePaidOnline = async (invoiceId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'marked-online'
    })).then(({ message }) => message)
}

export const recordOfflinePayment = async (invoiceId, amount) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'manual-payment'
    }), {
        amount
    }).then(({ message }) => message)
}

export const sendToEuler = async (invoiceId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        invoiceId,
        action: 'send-to-euler'
    }), {}).then(({ message }) => message)
}

export const getInvoicePreviewURL = (invoiceId) => {
    const url = generatePath(ENDPOINT_SINGLE_ACTION, {
        'action': 'preview-invoice',
        invoiceId
    })
    return `${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/${url}?${Date.now()}`
}
export const getInvoiceXMLURL = (invoiceId) => {
    const url = generatePath(ENDPOINT_SINGLE_ACTION, {
        'action': 'preview-invoice-xml',
        invoiceId
    })
    return `${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/${url}?${Date.now()}`
}

export const deleteInvoice = (invoiceId) => axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
    invoiceId,
    action: 'cancel'
})).then(({ message }) => message)

export const createCreditNote = (invoiceId) => axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
    invoiceId,
    action: 'credit-note',
}))

export const bulkInvoiceReminders = (invoiceIds, reminderType) => axiosInstance.post(generatePath(ENDPOINT_GENERAL_ACTION, {
    action: 'bulk-reminder'
}), {
    invoices: invoiceIds
}, {
    params: { reminder: reminderType },
}).then(({ message }) => message)

export const bulkInvoiceSendToClient = (invoiceIds) => axiosInstance.post(generatePath(ENDPOINT_GENERAL_ACTION, {
    action: 'send-bulk-client'
}), {
    invoices: invoiceIds
}).then(({ message }) => message)

export const checkAllInvoicePayments = () => axiosInstance.get(generatePath(ENDPOINT_GENERAL_ACTION, {
    action: 'fetch-all-twinfield-transactions'
})).then(({ message }) => message)

export const checkSingleInvoicePayments = (invoiceId) => axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
    invoiceId,
    action: 'fetch-twinfield-transactions'
})).then(({ message }) => message)

export const sendInvoiceToTwinfield = (invoiceId) => axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
    invoiceId,
    action: 'send-to-twinfield'
})).then(({ message }) => message)